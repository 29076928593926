import {
  SET_USERS,
  MERGE_USER,
  REMOVE_USER,
} from '../constants';
import { User } from '../../Types';

const init: { users: User[] } = {
  users: [],
}
export const user = (state = init, action) => {
  const { payload, type } = action;
  switch(type) {
    case SET_USERS:
      return {
        ...state,
        users: payload,
      }
    case MERGE_USER:
      const replaceIndex = state.users.findIndex(e => e.sub === payload.sub);
      return {
        ...state,
        users: replaceIndex >= 0 ?
          state.users.map((e, i) => i === replaceIndex ? payload : e) :
          state.users.concat(payload),
      }
    case REMOVE_USER:
      return {
        ...state,
        users: state.users.filter(u => u.sub !== payload),
      }
    default:
      return state;
  }
}
