import React, { Component } from 'react';
import { Col, Button } from 'reactstrap';
import {
  reduxForm,
  InjectedFormProps,
} from 'redux-form';
import { Recipient } from '../../Types';
import {
  createTextDisplay,
  createField,
} from '../../utils';

export type DeliveryRecipientFormProps = {
  recipient: Recipient | null;
  style?: any;
  initializeRecipient: (recipient: Recipient & { recipient: Recipient }) =>  void;
  setRecipientSearchTerm: (searchTerm: string) => void;
  recipients: Recipient[];
}

export const initialRecipientValues = {
  id: null,
  empId: null,
  lName: null,
  fName: null,
  init: null,
  bldg: null,
  room: null,
  workPhone: null,
  email: null,
  dept: null,
  recipient: null,
};

const fieldProps = {
  disabled: false,
  className: 'recipient-row-field',
};

const recipientTextField = (item: Recipient) => {
  // item should never be null, if it is, the wrong value is being passed into the Multiselect value prop of FieldFormControl
  if (typeof item === 'string') {
    return '';
  }
  const { lName, fName } = item;
  return lName && fName ? `${lName}, ${fName}` : '';
}

const recipientRowComp = (row) => {
  const { fName, lName } = row.item;
  return <span>{lName}, {fName}</span>
}

const DeliveryRecipientForm = (props) => <div className="recipient-form">
  <div className='recipient-row'>
  {
    createField(
      'recipient',
      'Recipient Search',
      'dropdownlist',
      {
        onSearch: props.setRecipientSearchTerm,
        searchTerm: props.recipientSearchTerm,
        multiple: false,
        textField: recipientTextField,
        rowComponent: recipientRowComp,
        filter: () => true,
        onChange: props.initializeRecipient,
        data: props.recipients,
      },
    )
  }
    <Button color='warning' style={{ marginTop: '1rem' }} onClick={() => props.initializeRecipient(initialRecipientValues)}>Clear</Button>
  </div>
  <div className="recipient-row">
    {createTextDisplay('empId', 'Employee Id', fieldProps)}
    {createTextDisplay('lName', 'Last Name', fieldProps)}
    {createTextDisplay('fName', 'First Name', fieldProps)}
  </div>
  <div className="recipient-row">
    {createTextDisplay('bldg', 'Building', fieldProps)}
    {createTextDisplay('dept', 'Department', fieldProps)}
    {createTextDisplay('room', 'Room', fieldProps)}
  </div>
  <div className="recipient-row">
    {createTextDisplay('workPhone', 'Work Phone', fieldProps)}
    {createTextDisplay('email', 'Email', fieldProps)}
  </div>
</div>

export default reduxForm<any, DeliveryRecipientFormProps>({
  initialValues: initialRecipientValues,
  form: 'recipient',
  destroyOnUnmount: false,
})(DeliveryRecipientForm);
