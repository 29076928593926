import React, { Component } from 'react';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import { createRow } from '../../utils';

import './DateRangePicker.css';

Moment.locale('en');
momentLocalizer();

export type DateRangePickerProps = {
  start: Date;
  end: Date;
  handleOnChange: (start: Date, end: Date) => void;
  style?: any;
  className?: string;
};

export type DateRangePickerState = {
  start: Date;
  end: Date;
};

class DateRangePicker extends Component<DateRangePickerProps, DateRangePickerState> {
  constructor(props) {
    super(props);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      start: props.start,
      end: props.end,
    }
  }

  handleDateChange(value: Date | undefined, isStart: boolean) {
    if (!value) {
      return;
    }
    const { start, end } = this.state;
    if (isStart) {
      this.setState({
        start: value,
      });
    } else {
      this.setState({
        end: value,
      });
    }
    this.props.handleOnChange(isStart ? value : start, !isStart ?  value : end);
  }

  render() {
    const { start, end, style, className } = this.props;
    return <div className='range-container' style={style}>
      <div className='range-field'>Start
        <DateTimePicker
          {...{className}}
          defaultValue={start}
          format={'MMM D, YYYY'}
          onChange={value => this.handleDateChange(value, true)}
          time={false}/>
        </div>
        <div className='range-field'>End
        <DateTimePicker
          {...{className}}
          defaultValue={end}
          format={'MMM D, YYYY'}
          onChange={value => this.handleDateChange(value, false)}
          time={false} />
        </div>
    </div>
  }
}

export default DateRangePicker;
