// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export const awsmobile_prod =  {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "sunyupstwebapp-20190115152031-hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://sunyupstwebapp-20190115152031-hostingbucket.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "sunyupstwebapp2bd6db79ace84febba264b7a9c3369fb",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://pn6nz3kfs5eq3jlbryiysftu6i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:7bc03bbc-bff5-4811-9826-6de4eaef70a8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_JtrESn4wM",
    "aws_user_pools_web_client_id": "4u6c2sasv1lq92j4adsnpebap7",
};
export const awsmobile_staging =  {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "sunyupstwebapp-staging-hosting",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://sunyupstwebapp-staging-hosting.s3-website-us-east-1.amazonaws.com",
    "aws_user_files_s3_bucket": "sunyupstwebapp2bd6db79ace84febba264b7a9c3369fb",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://sf2aihuuovfbji4egsvkr7dt7i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:33f60460-60a3-4660-b22b-c88b56ec59a5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Eqqs004xF",
    "aws_user_pools_web_client_id": "2fihrattol5cfddntcggtdi8qm",
};

export default awsmobile_prod;
