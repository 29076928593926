/**
 *
 * FieldFormControl
 * https://github.com/react-bootstrap/react-bootstrap/issues/2210#issuecomment-246933578
 *
 */

// import * as momentLocalizer from 'react-widgets-moment';
// import * as numberLocalizer from 'react-widgets-simple-number';

// should be rewritten; doesn't currently handle many of the custom props that react-widgets and reactstrap has
import * as React from 'react';
import {
  Col,
  FormGroup,
  FormText,
  Input,
  Label,
  Button,
} from 'reactstrap';
import {
  Combobox,
  DateTimePicker,
  Multiselect,
  NumberPicker,
  SelectList,
  DropdownList
} from 'react-widgets';
import { default as uuid } from 'uuid/v4'

import './FieldFormControl.css';
import {
  FieldFormControlTypePropType,
} from './constants';


export type FieldFormControlProptypes = {
  controlWidth?: number,
  data?: {
    valueField?: string,
    textField?: string,
  }[],
  hidden: boolean,
  input?: React.InputHTMLAttributes<HTMLInputElement> | any,
  label?: string,
  labelClass?: string,
  labelAlignment?: string,
  labelWidth?: number | string,
  tagComponent?: any | string,
  meta?: {
    error?: any,
    touched?: boolean,
    warning?: any,
  },
  multiple: boolean,
  rowComponent?: string | React.ComponentClass<any> | React.StatelessComponent<any>,
  sortData: any,
  type: FieldFormControlTypePropType,
  textField?: string,
  valueField?: string,
  formName?: string,
  id?: string,
  dateFormat?: any,
  onSelect?: any,
  defaultSearchTerm?: any,
} & any;

// momentLocalizer();
// numberLocalizer();


/**
 * Note, this function is kind of a mess, and not all component props are passed into their respective components, so you might need
 *  to destructure said props from the input and pass them into your component in the return.
 * @param props
 */
const FieldFormControl = (props: FieldFormControlProptypes) => {
  const {
    controlWidth,
    hidden,
    input,
    label,
    labelClass,
    labelWidth,
    meta: {
      touched,
      error,
      warning,
    },
    filter,
    format,
    multiple,
    rowComponent,
    sortData,
    textField,
    open,
    allowCreate,
    onCreate,
    disabled,
    onChange,
    searchTerm,
    defaultSearchTerm,
    popupTransition,
    listComponent,
    labelAlignment,
    minLength,
    onSearch,
    itemComponent,
    tagComponent,
    style,
    className,
    children,
  } = props;

  // const id = `${createHtmlId(`${formName}/${input.name}`)}` || '';
  const id = uuid();

  let type = props.type;
  if (type === 'routeselector' && multiple) {
    type = 'routeselector_multiple';
  }

  if (type === 'permissionselector' && multiple) {
    type = 'permissionselector_multiple';
  }

  let value = input.value;
  value = ['true', 'false'].includes(value) ? value === 'true' : value;

  const widgetsValidation = {
    onBlur: input.onBlur ? () => input.onBlur() : () => null,
    className: (touched && error ? 'form-control-error' : ''),
  };

  const getInputComponent = () => {
    // const inputTypes = ['text', 'textarea', 'checkbox', 'email', 'password', 'select'];

    const inputProps = {
      onSearch,
      multiple,
      disabled,
      popupTransition,
      listComponent,
      tagComponent,
      searchTerm,
      defaultSearchTerm,
      minLength,
      format,
      onChange: input.onChange,
    };
    const propData = props.data;
    const data = sortData && propData.length > 1 ? propData.sort(sortData) : propData;

    switch ((type || '').toLowerCase()) {
      case 'combobox':
        return (
          <Combobox
            {...widgetsValidation}
            {...inputProps}
            id={id}
            disabled={disabled}
            value={input.value}
            data={data}
            filter={filter}
            textField={textField}
            onSelect={input.onChange}
            itemComponent={rowComponent} />
        );
      case 'selectlist':
        return (
          <SelectList
          {...widgetsValidation}
          {...inputProps}
          id={id}
          value={value}
          data={data}
          onChange={input.onChange}
          itemComponent={rowComponent}
          />
        );
      case 'dropdownlist':
      case 'routeselector':
      case 'permissionselector':
        return (
          <DropdownList
            {...widgetsValidation}
            {...inputProps}
            {...{ style: { width: '100%' } }}
            id={id}
            value={input.value}
            data={data}
            filter={filter}
            textField={textField}
            itemComponent={rowComponent}
          />
        );

      case 'multiselect':
      case 'routeselector_multiple':
      case 'permissionselector_multiple':
        return (
          <Multiselect
            {...widgetsValidation}
            {...inputProps}
            {...{ style: { width: '100%' } }}
            id={id}
            value={input.value || []}
            data={data}
            filter={filter}
            open={open}
            textField={textField}
            allowCreate={allowCreate}
            onCreate={(value) => {
              if (allowCreate) {
                input.onChange(input.value.length && input.value.concat(value) || [value]);
                onCreate && onCreate(value);
              }
            }}
            itemComponent={rowComponent}
          />
        );
      case 'datetime':
      case 'datetimepicker':
      case 'calendar':
        if (props.dateFormat) {
          inputProps['format'] = props.dateFormat;
        }
        return (
          <DateTimePicker
            {...inputProps}
            id={id}
            {...{style: { flexGrow: 1 } }}
            time={props.time}
            value={value ? new Date(value) : undefined}
            onSelect={input.onChange} />
        );

      case 'number':
      case 'numberpicker':
        return (
          <NumberPicker
            {...inputProps}
            id={id}
            value={value ? Number(value) : undefined} />
        );

      case 'readonly':
        return (
          <label style={{ paddingTop: 6 }}>{value.textField || value}</label>
        );

      case 'textarea':
      case 'text':
      case 'select':
      case 'checkbox':
      case 'email':
      case 'password':
        return (
          <Input
            {...input}
            id={id}
            type={type}
            multiline={(type === 'textarea').toString()}
            state={(touched && error) ? 'error' : null}
            value={value}
            checked={value}
            disabled={disabled}
            hidden={hidden}
            style={style}
          />
        );

      default: return null;
    }
  };

  return (
    <div className={className ? `ffc ${className}` : 'ffc'}>
      <div className={labelAlignment === 'row' ? 'ffc-row' : 'ffc-column'}>
        {label && <span className='ffc-label' hidden={hidden}>
            {label}
        </span>}
        <div className="ffc-input-row">{getInputComponent()}{children}</div>
      </div>

      {touched && (
          (error &&
            <FormText className='ffc-error'>
              {error}
            </FormText>) ||
          (warning &&
            <FormText className='ffc-warning'>
              {warning}
            </FormText>)
        )}
    </div>
  );
};

export default FieldFormControl;
