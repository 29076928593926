import {
  all,
  takeEvery,
  put,
} from 'redux-saga/effects';

import { Storage } from 'aws-amplify';

import { POPULATE_RECIPIENTS } from '../constants';
import { setRecipients } from '../actions';

import { RECIPIENTS_BUCKET } from '../../data';

export const populateRecipientsSaga = function* () {
  try {
    let rList;
    try {
      const file = yield Storage.get('mailroom_person_import.csv', {
        bucket: RECIPIENTS_BUCKET,
        download: true,
        level: 'public',
      });
      const lines = (new TextDecoder('utf-8')).decode(file.Body).split('\n');
      const keys = lines[0].replace(/\r/g, '').split(',');
      rList = lines.splice(1).reduce<any[]>((list, line) => {
        const valueArray = line.replace(/\r/g, '').split(',');
        if (line.length) {
          const obj = keys.reduce((obj, key, index) => {
            const value = valueArray[index];
            obj[key] = value.length && value || null;
            return obj;
          }, {});
          list.push(obj)
        }
        return list;
      }, []);
    } catch (err) {
      rList = [];
      console.log('populateReceiptSaga err', err);
    }

    yield put(setRecipients(rList));
  } catch (err) {
    console.log('populateReceiptSaga err', err);
  }
}

export default function* () {
  yield all([
    function* () { yield takeEvery(POPULATE_RECIPIENTS, populateRecipientsSaga) }(),
  ])
}
