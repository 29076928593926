import { Redirect } from 'react-router';
import React, { Component } from 'react';
// redux
import { connect } from 'react-redux';
import { initialize } from 'redux-form'
import {
  populateUsers,
  populateCurrentUser,
  subscribeToUsers,
  putUser,
  deleteUser,
  resetUserPassword,
} from '../../redux/actions';

// ui elements
import { Modal } from '../../components';
import { createRow } from '../../utils';
import UserForm, { initialValues } from './UserForm';
import UsersTable from './UserTable';
// css
import styled, { StyledComponentClass } from 'styled-components';
// Types and data constants
import { User } from '../../Types';
import { Role } from '../../data';


export type UsersContainerProps = {
  // redux action
  populateUsers: () => void;
  populateCurrentUser: () => void;
  subscribeToUsers: () => void;
  putUser: (user: User) => void;
  deleteUser: (user: User) => void;
  resetUserPassword: (user: User) => void;
  // redux data
  users: User[];
  currentUser: User;
  // redux-form
  initializeForm: (user: User) => void;
}

export type UsersContainerState = {
  currentInitialValues: User | null;
  modalUser: User | null;
}

const UsersScreenWrapper: StyledComponentClass<any, any> = styled.div`
  padding: 0px 10px 0px 10px
`

class UsersContainer extends Component<UsersContainerProps, UsersContainerState> {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleInitEditUser = this.handleInitEditUser.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleResetUserPassword = this.handleResetUserPassword.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.state = {
      currentInitialValues: initialValues,
      modalUser: null,
    }
  }

  componentDidMount() {
    const {
      populateUsers,
      populateCurrentUser,
      subscribeToUsers
    } = this.props;
    populateUsers();
    subscribeToUsers();
    populateCurrentUser();
    this.handleClearForm();
  }

  handleOpenModal(user: User) {
    this.setState({
      modalUser: user,
    });
  }

  handleCloseModal() {
    this.setState({
      modalUser: null,
    });
  }

  handleDeleteUser() {
    const user = this.state.modalUser;
    if (user) {
      this.props.deleteUser(this.state.modalUser as User);
    }
    this.handleCloseModal();
  }

  handleClearForm() {
    const {
      initializeForm,
    } = this.props;
    initializeForm(initialValues);
    this.setState({
      currentInitialValues: null,
    })
  }

  handleInitEditUser(user: User) {
    const {
      initializeForm,
    } = this.props;
    initializeForm(user);
    this.setState({
      currentInitialValues: user,
    });
  }

  handleResetUserPassword(user: User) {
    const {
      resetUserPassword,
      initializeForm,
    } = this.props;
    resetUserPassword(user);
    this.handleClearForm();
  }

  async onSubmit(values: User) {
    const {
      initializeForm,
      putUser,
    } = this.props;

    putUser(values);
    this.handleClearForm();
  }

  render() {
    const {
      users,
      deleteUser,
      currentUser,
    } = this.props;
    const { currentInitialValues, modalUser } = this.state;

    if (currentUser && currentUser.role !== Role.admin) {
      return <Redirect to='/' />
    }

    const modal = <Modal
      confirmSubmit
      title={`Confirm Delete User: ${modalUser && modalUser.username}`}
      submitText="Delete"
      onSubmit={this.handleDeleteUser}
      onCancel={this.handleCloseModal}
      isOpen={!!modalUser}
    />
    const table = <UsersTable
      users={users || []}
      handleInitForm={this.handleInitEditUser}
      deleteUser={this.handleOpenModal}
      currentUser={currentUser}
    />
    const form = <UserForm
      currentUser={currentUser}
      onSubmit={this.onSubmit}
      handleInitForm={this.handleClearForm}
      handleResetUserPassword={this.handleResetUserPassword}
      editingUser={currentInitialValues || undefined}
    />
    const row = createRow([
      table,
      form,
    ]);

    return <UsersScreenWrapper>{modal}{row}</UsersScreenWrapper>;
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    currentUser: state.auth.currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  initializeForm: (user: User) => dispatch(initialize('user',  user)),
  populateUsers: () => dispatch(populateUsers()),
  populateCurrentUser: () => dispatch(populateCurrentUser()),
  subscribeToUsers: () => dispatch(subscribeToUsers()),
  putUser: (user: User) => dispatch(putUser(user)),
  deleteUser: (user: User) => dispatch(deleteUser(user)),
  resetUserPassword: (user: User) => dispatch(resetUserPassword(user)),
})

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(UsersContainer)
