import {
  SET_AUTH_STATE,
  SET_CURRENT_USER,
  SIGN_OUT,
} from '../constants';
import {
  Department,
  Role,
} from '../../Types';

const depList = Object.values(Department);

const init = {
  authState: null,
  currentUser: null,
}
export const auth = (state = init, action) => {
  const { payload, type } = action;
  switch(type) {
    case SET_AUTH_STATE:
      return {
        ...state,
        authState: payload,
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      }
    case SIGN_OUT:
      return {
        ...state,
        currentUser: null,
      }
    default:
      return state;
  }
}
