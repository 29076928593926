import {
  SET_RECIPIENTS,
  SET_RECIPIENT_SEARCH_TERM,
} from '../constants';

const init = {
  recipients: [],
  searchTerm: '',
}
export const recipient = (state = init, action) => {
  switch(action.type) {
    case SET_RECIPIENTS:
      return {
        ...state,
        recipients: action.payload,
      }
    case SET_RECIPIENT_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      }
    default:
      return state;
  }
}
