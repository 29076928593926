import * as React from 'react';
import { Button } from 'reactstrap';
import styled, { StyledComponentClass } from 'styled-components';
import {
  reduxForm,
  InjectedFormProps,
} from 'redux-form';
import {
  createField,
  createCol,
  createLabelWidthProps,
  isEmail,
  normalizePhone,
} from '../../utils';

import { Department, Role } from '../../data';
import { User } from '../../Types';

const depList = Object.values(Department);
const roleList = Object.values(Role);

export type UserFormProps = {
  onSubmit: (values: User) => Promise<void>;
  handleInitForm: (initValues: User) => void;
  handleResetUserPassword: (userName: User) => void;
  editingUser?: User;
  currentUser: User;
};


const validate = (values, props) => {
  const currentUser = props.currentUser as Required<User>;
  const errors: any = {};
  if (!values.username) {
    errors.username = 'Must provide username.';
  } else if (values.isDev && (!currentUser || !currentUser.isDev)) {
    errors.username = 'Cannot create a maintenance user as a non-maintenance user.';
  }
  if (!isEmail(values.email)) {
    errors.email = 'Must be a valid email.';
  }
  return errors;
}

const constantFields = [
  createField(
    'firstName',
    'First Name',
    'text',
    {
      ...createLabelWidthProps(2)
    },
  ),
  createField(
    'lastName',
    'Last Name',
    'text',
    {
      ...createLabelWidthProps(2),
    }
  ),
  createField(
    'phone',
    'Phone Number',
    'text',
    {
      ...createLabelWidthProps(2),
      normalize: normalizePhone,
    }
  ),
  createField(
    'email',
    'Email',
    'email',
    {
      ...createLabelWidthProps(2),
    }
  ),
  createField(
    'role',
    'Role',
    'dropdownlist',
    {
      ...createLabelWidthProps(2),
      data: roleList,
      defaultValue: Role.user,
    }
  ),
  createField(
    'enabled',
    'Enabled',
    'checkbox',
    {
      ...createLabelWidthProps(2),
    }
  )
];

const TitleWrapper: StyledComponentClass<any, any> = styled.div`
  margin: 3vh 0vw 3vh 0vw
  font-size: 24px
`

export const initialValues: User = {
  username: undefined,
  email: undefined,
  sub: undefined,

  role: Role.user,

  firstName: undefined,
  lastName: undefined,
  enabled: true,
  phoneNumber: undefined,
};


const UserForm = (props: UserFormProps) => {
  const {
    submitting,
    onSubmit,
    handleSubmit,
    valid,
    handleInitForm,
    handleResetUserPassword,
    initialValues,
    currentUser,
    editingUser
  } = props as UserFormProps & InjectedFormProps<User>;

  if (!currentUser) {
    return null;
  }

  const usernameField = createField(
    'username',
    'Username',
    'text',
    {
      ...createLabelWidthProps(2),
      disabled: !!editingUser,
    }
  );
  const departmentField = createField(
    'department',
    'Department',
    'dropdownlist',
    {
      ...createLabelWidthProps(2),
      data: currentUser.isDev || currentUser.department === 'all' ? depList : [currentUser.department],
      defaultValue: currentUser.department,
    }
  );

  const fields = [usernameField, departmentField].concat(constantFields);

  return (
    <div>
      <TitleWrapper>{editingUser ? `Edit User ${editingUser.username}` : 'Create User'}</TitleWrapper>
      <div>
        {createCol(fields)}
      </div>
      <Button
        type="submit"
        style={{ width: 240, float: 'right' }}
        color="success"
        disabled={submitting || !valid}
        onClick={handleSubmit(onSubmit)}
      ><i className={`fa ${submitting ? 'fa-circle-o-notch fa-spin' : 'fa-save'} `} />Save</Button>
      <Button
        color="warning"
        style={{ float: 'right', marginRight: '.25vw' }}
        disabled={submitting}
        onClick={() => handleInitForm(initialValues)}
      ><i className={`fa ${submitting ? 'fa-circle-o-notch fa-spin' : 'fa-save'} `} />Cancel</Button>
    </div>
  );
};

export default reduxForm<any, UserFormProps>({
  initialValues,
  validate,
  form: 'user',
})(UserForm);
