import React, { Component } from 'react';
// redux
import * as isEqual from 'lodash.isequal';
import { connect } from 'react-redux';
import { initialize, formValueSelector, change, blur, isDirty, SubmissionError } from 'redux-form'
import { actions as toastrActions } from 'react-redux-toastr';
import { API, graphqlOperation } from 'aws-amplify';
import {
  listDelivery,
} from '../../graphql/queries';
import {
  newDebounce,
} from '../../utils';
import { v4 as uuid } from 'uuid';
import { Filter } from '../../components/Filter';
import {
  deleteDelivery,
  populateDeliveries,
  populateRecipients,
  putDelivery,
  setRange,
  setViewUndeliverable,
  subscribeToDeliveries,
  putDeliveryAndPrint,
  setPrintFlag,
  setTrackingSearch,
  setRecipientSearchTerm,
  toggleDeliveryModal,
} from '../../redux/actions';

import './Delivery.css';

// ui elements
import { Input } from 'reactstrap';
import { createRow, setEndHour, epoch, iso } from '../../utils';
import { Modal, DateRangePicker, DeliveryLookupModal } from '../../components';
import DeliveryPrint from './DeliveryPrint';
import DeliveryTable from './DeliveryTable';
import DeliveryForm, { initialValues } from './DeliveryForm';
import DeliveryRecipientForm, { initialRecipientValues } from './DeliveryRecipientForm';
import { initialDeliveryPrintValues } from './DeliveryPrint';

// Types
import { Delivery, Recipient, objSchemaMap } from '../../Types';

export type DeliveryContainerProps = {
  // redux action
  populateDeliveries: (filter: any, setQueryRunning: (b: boolean) => void) => void;
  populateRecipients: () => void;
  subscribeToDeliveries: () => void;
  setRange: (start: Date, end: Date) => void;
  setViewUndeliverable: (viewUndeliverable: boolean) => void;
  putDelivery: (delivery: Delivery) => void;
  putDeliveryAndPrint: (delivery: Delivery) => void;
  setPrintFlag: (shouldPrint: boolean) => void;
  deleteDelivery: (delivery: Delivery) => void;
  setRecipientSearchTerm: (searchTerm: string) => void;
  setTrackingSearch: (trackingSearch: string) =>  void;
  errorToastAction: (title: string, msg: string) => void;
  setBarcodes: (barcodes: string[]) => void;
  setDateReceived: (date: Date) =>  void;
  // redux data
  printFlag: boolean;
  start: Date;
  end: Date;
  viewUndeliverable: boolean;
  trackingSearch: string;
  deliveries: Delivery[];
  recipients: Recipient[];
  currentUser: any;
  recipientSearchTerm: string;
  // redux-form
  initializeForm: (delivery: Delivery) => void;
  initializeRecipient: (r: Recipient & { recipient: Recipient } ) => void;
  initializePrint: (r: Delivery) => void;
  currentFormId: string;
  setDeliveryModal: (r: Delivery | null) => void;
  delivery: Delivery;
  deliveryModalOpen: boolean;
  recipientFormValues: any;
  recipientFormDirty: boolean;
  deliveryFormDirty: boolean;
}

export type DeliveryContainerState = {
  modalDelivery: Delivery | null;
  filter: any,
  queryRunning: boolean,
}

const recipientSortData = (a: Recipient, b: Recipient) => {
  const aNameL: string = a.lName;
  const bNameL: string = b.lName;
  const lastNameCompare = aNameL.localeCompare(bNameL);
  return lastNameCompare === 0 ? a.fName.localeCompare(b.fName) : lastNameCompare;
};

const maxDisplayCount = 300;
let displayedCount = 0;
const recipientFilter = (searchTerm, item, index) => {
  if (index === 0) {
    displayedCount = 0;
  }
  if (displayedCount >= maxDisplayCount) {
    return false;
  }

  const term = searchTerm ? searchTerm.toUpperCase() : '';
  const searchTerms = term.split(' ');
  const values: string[] = Object.values<number | boolean | string>(item)
    .filter(v => v != null)
    .map(v => v.toString().toUpperCase());

  let shouldDisplay = false;
  if (searchTerms.every(term => values.some(v => v.includes(term)))) {
    shouldDisplay = true;
  }
  if (shouldDisplay) {
    displayedCount++;
  }
  return shouldDisplay;
};

class DeliveryContainer extends Component<DeliveryContainerProps, DeliveryContainerState> {
  updateFilter: any;

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPrint = this.onSubmitPrint.bind(this);
    this.handleTrackingFieldChange = this.handleTrackingFieldChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalOnCancel = this.handleModalOnCancel.bind(this);
    this.handleDeleteDelivery = this.handleDeleteDelivery.bind(this);
    this.handleInitForm = this.handleInitForm.bind(this);
    this.handleAfterPrint = this.handleAfterPrint.bind(this);
    this.setQueryRunning = this.setQueryRunning.bind(this);
    const debounceUpdateFilter = newDebounce(filter => {
        this.setState({
          filter,
        });
    }, 3000);
    this.updateFilter = debounceUpdateFilter.bind(this);

    this.state = {
      filter: null,
      queryRunning: false,
      modalDelivery: null,
    };
  }

  componentDidMount() {
    const {
      populateRecipients,
      populateDeliveries,
      subscribeToDeliveries,
    } = this.props;
    populateRecipients()
    subscribeToDeliveries();
  }
  // componentDidUpdate(prevProps, prevState) {
  //   if (!this.state.listPopulatedOnce && this.state.filter) {
  //     this.props.populateDeliveries(this.state.filter, this.setQueryRunning);
  //   }
  // }
  handleTrackingFieldChange(trackings: string[], previousTracking: string[], allValues?) {
    return trackings;
  }
  async handleModalOpen(delivery: Delivery) {
    this.setState({
      modalDelivery: delivery,
    });
  }
  handleModalOnCancel() {
    this.setState({
      modalDelivery: null,
    });
  }
  handleDeleteDelivery() {
    const { modalDelivery } = this.state;
    if (modalDelivery) {
      try {
        this.props.deleteDelivery(modalDelivery);
      } catch (err) {

      }
    }
    this.handleInitForm(initialValues);
    this.handleModalOnCancel();
  }
  handleInitForm(d: Delivery) {
    const { initializeForm, initializeRecipient, initializePrint } = this.props;
    initializeForm(d);
    initializeRecipient(d && d.recipient ? { ...d.recipient, recipient: d.recipient } : initialRecipientValues as any);
    initializePrint(d || initialDeliveryPrintValues as any);
  }

  async validateBarcodes (delivery: Delivery) {
    const filter: any = {
      barcodes: {
        some: {
          within: delivery.barcodes,
        },
      },
    }

    if (delivery.id) {
      filter.id = {
        neq: delivery.id,
      };
    }

    let deliveries: any = null;
    try {
      deliveries = await API.graphql(graphqlOperation(listDelivery, { limit: 1000, filter })) as any;
    } catch (err) {
      console.log('listDelivery error', err);
      throw new SubmissionError({
        barcodes: `Something went wrong while validating the barcodes.`,
      });
    }
    const items = deliveries.data.listDelivery.items;
    if (items.length) {
      const duplicates = items.reduce((dups, next) => {
        const dup = delivery.barcodes.find(bc => next.barcodes.includes(bc));
        if (!dups.includes(dup)) {
          dups.push(dup);
        }
        return dups;
      }, []);
      throw new SubmissionError({
        barcodes: `${duplicates.join(', ')} ${duplicates.length > 1 ? 'are' : 'is'} already in use by another delivery.`,
      });
    }
  }

  async assemblePayload (
    values: Delivery & { barcodes: { value: string, id: string }[] },
    recipientFormValues: Recipient,
    currentUser: { sub: string, username: string }
  ) {
    const currentDate = (new Date()).toISOString();
    const { recipient, barcodes, id, note, undeliverable, dateReceived, department } = values;
    const { username, sub } = currentUser;
    const payload: Delivery = {
      id,
      barcodes: barcodes.map(b => (b as unknown as { value: string, id: string }).value),
      department,
      dateCreated: values.dateCreated || currentDate,
      dateModified: currentDate,
      createdBy: values.createdBy || username,
      createdBySub: values.createdBySub || sub,
      modifiedBy: username,
      modifiedBySub: sub,
      recipient: recipientFormValues,
      note,
      dateReceived: dateReceived != null ? iso(dateReceived) : currentDate,
      undeliverable,
    }
    await this.validateBarcodes(payload);
    return payload;
  }

  setQueryRunning = (queryRunning: boolean) => {
    this.setState({
      queryRunning,
    });
  }

  async onSubmit(values: any) {
    const {
      putDelivery,
      currentUser,
      recipientFormValues,
      initializeForm,
      initializeRecipient,
    } = this.props;

    const payload = await this.assemblePayload(values, recipientFormValues, currentUser);
    putDelivery(payload);
    // initializeForm(initialValues);
    // initializeRecipient(initialRecipientValues as any);
    // initializePrint(initialRecipientValues as any);
  }
  async onSubmitPrint(values: any) {
    const {
      putDeliveryAndPrint,
      currentUser,
      recipientFormValues,
    } = this.props;

    const payload = await this.assemblePayload(values, recipientFormValues, currentUser);
    putDeliveryAndPrint(payload);
  }

  handleAfterPrint () {
    const {
      initializeForm,
      initializeRecipient,
      initializePrint,
    } = this.props;
    initializeForm(initialValues);
    initializeRecipient(initialRecipientValues as any);
    initializePrint(initialRecipientValues as any);
  }

  render() {
    const {
      start,
      end,
      deliveries,
      recipients,
      initializeRecipient,
      currentFormId,
      currentUser,
      setPrintFlag,
      setViewUndeliverable,
      printFlag,
      trackingSearch,
      recipientFormValues,
      recipientSearchTerm,
      setRecipientSearchTerm,
      delivery,
      viewUndeliverable,
      deliveryModalOpen,
      setDeliveryModal,
      setTrackingSearch,
      recipientFormDirty,
      deliveryFormDirty,
      setDateReceived,
    } = this.props;
    const { modalDelivery } = this.state;

    const filteredDeliveries = deliveries; // deliveryFilter(deliveries, viewUndeliverable, start, end, trackingSearch) || [];

    const modalDate = modalDelivery && (new Date(modalDelivery.dateReceived).toLocaleDateString());
    const modalTitle = `${modalDate} to ${modalDelivery && modalDelivery.recipient.lName}`
    const recipientsSorted = recipients.sort(recipientSortData).filter((e, i) => recipientFilter(recipientSearchTerm, e, i)) || [];
    // console.log('recipientSorted.length', recipientsSorted.length);
    const deliveryModal = (<DeliveryLookupModal
      delivery={delivery}
      onClose={setDeliveryModal}
      isOpen={deliveryModalOpen}
    />);

    const modal = <Modal
      title={`Delete Delivery: ${modalTitle}`}
      confirmSubmit
      submitText="Delete"
      onSubmit={this.handleDeleteDelivery}
      onCancel={this.handleModalOnCancel}
      isOpen={!!modalDelivery}
    />;

    const onQuery = () => {
      console.log('this.state.filter', this.state.filter);
      this.props.populateDeliveries(this.state.filter, this.setQueryRunning);
    };
    const updateFilter = (filter) => {
      this.setState({
        filter,
      });
    }

    const tableContainer = <div className='table-container'>
        <Filter onQuery={onQuery} queryDisabled={this.state.queryRunning} onFilterChange={updateFilter} currentUser={this.props.currentUser} />
        <DeliveryTable
          deliveries={filteredDeliveries}
          initForm={this.handleInitForm}
          toggleDeliveryModal={setDeliveryModal}
        />
    </div>;
    const unsavedChanges = <div className="unsaved-changes">*There are unsaved changes.</div>
    const formRecipient = <div>
      <DeliveryForm
        handleTrackingFieldChange={this.handleTrackingFieldChange}
        onDelete={this.handleModalOpen}
        onSubmit={this.onSubmit}
        onSubmitPrint={this.onSubmitPrint}
        initializeForm={this.handleInitForm}
        setDateReceived={setDateReceived}
        currentFormId={currentFormId}
        onAfterPrint={this.handleAfterPrint}
        setPrintFlag={setPrintFlag}
        printFlag={printFlag}
        recipientFormDirty={recipientFormDirty}
      >
        <DeliveryRecipientForm
          style={{ marginTop: '15vh'}}
          recipient={null}
          initializeRecipient={initializeRecipient}
          setRecipientSearchTerm={setRecipientSearchTerm}
          recipients={recipientsSorted}
        />
      </DeliveryForm>

      {(recipientFormDirty || deliveryFormDirty) && unsavedChanges}
    </div>
    const row = createRow([
      tableContainer,
      formRecipient,
    ]);


    return <div className="delivery-container">{delivery && deliveryModal}{modal}{row}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    start: state.delivery.start,
    end: state.delivery.end,
    recipients: state.recipient.recipients,
    deliveries: state.delivery.deliveries,
    viewUndeliverable: state.delivery.viewUndeliverable,
    trackingSearch: state.delivery.trackingSearch,
    printFlag: state.print.printFlag,
    currentUser: state.auth.currentUser,
    recipientSearchTerm: state.recipient.searchTerm,
    currentFormId: formValueSelector('delivery')(state, 'id'),
    deliveryModalOpen: state.delivery.deliveryModalOpen,
    delivery: state.delivery.delivery,
    recipientFormValues: formValueSelector('recipient')(state, 'empId', 'lName','fName','bldg','dept','room','workPhone','email'),
    recipientFormDirty: isDirty('recipient')(state),
    deliveryFormDirty: isDirty('delivery')(state),
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  initializeForm: (d: Delivery) => dispatch(initialize('delivery', { ...d, barcodes: d.barcodes ? d.barcodes.map(b => ({
    id: uuid(),
    value: b,
  })) : null })),
  initializeRecipient: (r: Recipient) => dispatch(initialize('recipient', r)),
  initializePrint: (d: Delivery) => dispatch(initialize('print', d)),
  errorToastAction: (title: string, message?: string) => dispatch(toastrActions.add({
    message,
    title,
    type: 'error',
    position: 'top-center',
    attention: true,
    onAttentionClick: () => {},
    options: {
      attention: true,
      showProgressBar: false,
      closeOnToastrClick: false,
      showCloseButton: true,
    }
  })),
  populateRecipients: () => dispatch(populateRecipients()),
  populateDeliveries: (filter: any, queryRunning: any) => dispatch(populateDeliveries(filter, queryRunning)),
  subscribeToDeliveries: () => dispatch(subscribeToDeliveries()),
  putDelivery: (delivery: Delivery) => dispatch(putDelivery(delivery)),
  putDeliveryAndPrint: (delivery: Delivery) => dispatch(putDeliveryAndPrint(delivery)),
  setPrintFlag: (shouldPrint: boolean) =>  dispatch(setPrintFlag(shouldPrint)),
  setRange: (start: Date, end: Date) => dispatch(setRange(start, end)),
  setViewUndeliverable: (viewUndeliverable: boolean) => dispatch(setViewUndeliverable(viewUndeliverable)),
  deleteDelivery: (delivery: Delivery) => dispatch(deleteDelivery(delivery)),
  setRecipientSearchTerm: (searchTerm: string) => dispatch(setRecipientSearchTerm(searchTerm)),
  setDeliveryModal: (delivery: Delivery) => dispatch(toggleDeliveryModal(delivery)),
  setBarcodes: (barcodes: string[]) => dispatch(change('delivery', 'barcodes', barcodes)),
  setDateReceived: (date: Date) => dispatch(change('delivery', 'dateReceived', date)),
  setTrackingSearch: (trackingSearch: string) => dispatch(setTrackingSearch(trackingSearch)),
})

export default connect<any, any, any>(mapStateToProps, mapDispatchToProps)(DeliveryContainer)
