import React, { Component } from 'react';
import Modal from 'react-modal';
import { Button, Input, Row, Col } from 'reactstrap';

export type ModalProps = {
  title: string;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  confirmSubmit?: boolean;
  cancelText?: string;
  submitText?: string;
}

export type ModalState = {
  confirmed: boolean;
}

class CustomModal extends Component<ModalProps, ModalState> {
  constructor(props) {
    super(props);
    this.handleConfirmedStateChange = this.handleConfirmedStateChange.bind(this);
    this.state = {
      confirmed: false,
    };
  }

  handleConfirmedStateChange(event) {
    this.setState({
      confirmed: event.target.checked,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      this.setState({
        confirmed: false,
      });
    }
  }

  render() {
    const {
      title,
      isOpen,
      onCancel,
      onSubmit,
      confirmSubmit,
      cancelText,
      submitText,
    } = this.props;
    const { confirmed } = this.state;
    const submitActive = !confirmSubmit || confirmed;

    return <Modal
      style={{
        content: {
          margin: 'auto',
          height: 'max-content',
          width: 'max-content',
        }
      }}
      ariaHideApp={false}
      isOpen={isOpen}
    >
      <div
        style={{
          fontSize: '24px',
        }}
      >{title}</div>
      <div
        style={{
          marginTop: '1.5vh',
          float: 'right',
        }}
      >
        {confirmSubmit && <Row>
          <Col>Confirm</Col>
          <Col><Input
            id='confirmSubmit'
            type='checkbox'
            onChange={this.handleConfirmedStateChange}
          /></Col>
        </Row>}
        <Row>
          <Button
            style={{
              marginRight: '.25vw',
            }}
            color="warning"
            onClick={onCancel}
          >{cancelText || 'Cancel'}</Button>
          <Button
            style={{
              marginRight: '.25vw',
            }}
            color={submitActive && 'danger' || 'secondary'}
            onClick={onSubmit}
            disabled={!submitActive}
          >{submitText || 'Submit'}</Button>
        </Row>
      </div>
    </Modal>
  }
}

export default CustomModal;
