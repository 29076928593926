import {
  SET_AUTH_STATE,
  SET_CURRENT_USER,
  POPULATE_CURRENT_USER,
  SIGN_OUT,
} from '../constants';
import { User } from '../../Types';

/**
 * set authState in the store
 * @param authState
 */
export const setAuthState = (authState: string) => ({
  payload: authState,
  type: SET_AUTH_STATE,
});

/**
 * set currentUser in the store
 * @param user
 */
export const setCurrentUser = (user: User) => ({
  payload: user,
  type: SET_CURRENT_USER,
})

/**
 * trigger call for currentUser to be retrieved and set in the store
 */
export const populateCurrentUser = () => ({
  payload: null,
  type: POPULATE_CURRENT_USER,
});

export const signOut = () => ({
  payload: null,
  type: SIGN_OUT,
})

