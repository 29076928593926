import { all } from 'redux-saga/effects';

import { default as delivery } from './delivery';
import { default as recipient } from './recipient';
import { default as auth } from './auth';
import { default as user } from './user';
import { default as print } from './print';

export default function * root () {
  return yield all([
    delivery(),
    recipient(),
    auth(),
    user(),
    print(),
  ]);
}
