import React, { Component } from 'react';
import { DateTimePicker } from 'react-widgets';
import { reduxForm, InjectedFormProps } from 'redux-form';
import {
    initialize,
    formValueSelector,
    change,
    blur,
    isDirty,
    SubmissionError,
} from 'redux-form'
import { createField } from '../../utils';
import { Button } from 'reactstrap';
import {
    decDay,
    setZeroHour,
} from '../../utils';

import './Archive.css';

type QueryFormProps = {
    onSubmit: any,
};

export type QueryFormValues = {
    start: Date,
    end: Date,
}

export const initialValues: QueryFormValues = {
    start: decDay(setZeroHour(new Date()), 7),
    end: setZeroHour(new Date()),
};


const QueryFormComponent = (props: QueryFormProps & InjectedFormProps) => {
    const {
        handleSubmit,
        onSubmit,
    } = props;
    return <div className="query-form-container">
          {createField(
                'start',
                'Start',
                'datetimepicker',
                { time: false }
            )}
            <div style={{ width: '100%', marginLeft: '.3rem' }}>
                {createField(
                    'end',
                    'End',
                    'datetimepicker',
                    { style: { marginLeft: '.3rem' }, time: false }
                )}
            </div>

        <Button
            color='info'
            className='query-button'
            onClick={handleSubmit(onSubmit)}
        >
            Query
        </Button>
    </div>
};

export const QueryForm = reduxForm<any, QueryFormProps>({
    initialValues,
    // validate,
    form: 'query',
})(QueryFormComponent as any);