import { Auth } from 'aws-amplify';
// sagas
import { put, all, takeEvery, select } from 'redux-saga/effects';
import {
  takeFirst,
  localStorage_removeCurrentAuth,
} from '../../utils';
// redux
import { setCurrentUser } from '../actions';
import {
  POPULATE_CURRENT_USER,
  SIGN_OUT,
} from '../constants';
// Types
import { User, Department, Role } from '../../Types';

const depList = Object.values(Department);
const roles = [Role.admin, Role.user];

export const populateCurrentUserSaga = function* () {
  try {
    const currentUser = yield Auth.currentSession();
    const payload = currentUser.idToken.payload;
    const groups = payload['cognito:groups'];
    const user: User = {
      groups,
      username: payload['cognito:username'],
      sub: payload.sub,
      email: payload.email,
      role: groups.find(g => roles.includes(g)),
      isAdmin: groups.includes(Role.admin),
      isDev: groups.includes(Role.dev),
      department: groups.find(g => depList.includes(g)),
    };
    localStorage.setItem('currentUser', JSON.stringify(user));
    yield put(setCurrentUser(user));
  } catch (err) {
    console.log('populateCurrentUserSage error', err);
  }
}

export const signOutSaga = function* () {
  localStorage_removeCurrentAuth();
  yield Auth.signOut();
}

export default function* () {
  yield all([
    function* () { yield takeFirst(SIGN_OUT, signOutSaga) }(),
    function* () { yield takeFirst(POPULATE_CURRENT_USER, populateCurrentUserSaga) }(),
  ])
}