export const sanitizeEmptyStrings = (input) => {
  if (input == null) {
    return input;
  }
  const output = {};
  Object.keys(input).forEach((key) => {
    const value = input[key];
    if (typeof value === 'object' && !Array.isArray(value)) {
      output[key] = sanitizeEmptyStrings(value);
    } else if (typeof value === 'string' && !value.length) {
      output[key] = null;
    } else {
      output[key] = value;
    }
  });
  return output;
}

export const sanitizeGraphqlRes = (input: any) => {
  const obj: any = {};
  Object.keys(input).forEach(key => {
    const value = input[key];
    if (typeof value === 'object' && value !== null) {
      if (Array.isArray(value)) {
        obj[key] = value.map(i => typeof i === 'object' && i !== null ? sanitizeGraphqlRes(i) : i);
      } else {
        obj[key] = sanitizeGraphqlRes(value);
      }
    } else if (key !== '__typename') {
      obj[key] = input[key];
    }
  });
  return obj;
}