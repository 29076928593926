import React, { Component } from 'react';
import {
    Combobox,
    DateTimePicker,
    Multiselect,
    NumberPicker,
    SelectList,
    DropdownList
} from 'react-widgets';
import { User } from '../../Types';
import { Input, Button } from 'reactstrap';
import * as isEqual from 'lodash.isequal';
import { decDay, incDay, setZeroHour, deep_merge, xzibit, prevIfEqual } from '../../utils';

import './Filter.css';
import { v4 as uuid } from 'uuid';

export enum NumberFilters {
	neq = 'not equal',
	eq = 'equal',
	lte = 'less than or equal',
	lt = 'less than',
	gte = 'greater than or equal',
	gt = 'greater than',
	between = 'between',
	within = 'within',
};
export enum StringFilters {
	neq = 'not equal',
	eq = 'equal',
	lte = 'less than or equal',
	lt = 'less than',
	gte = 'greater than or equal',
	gt = 'greater than',
	endsWith = 'ends with',
	startsWith = 'starts with',
	includes = 'includes',
	includesCaseInsensitive = 'includes case insensitive',
	notIncludes = 'not includes',
	between = 'between',
	within = 'within',
};
export enum ArrayFilters {
    between = 'between',
	within = 'within',
};

export enum DateFilters {
    lt = 'before',
    gt = 'after',
}

export enum BooleanFilters {
    eq = 'equal',
    neq = 'not equal'
};

export enum ListFilters {
    some = 'some',
	every = 'every',
};

export enum FilterType {
    string = 'string',
    float = 'float',
    integer = 'integer',
    boolean = 'boolean',
    date = 'date',
};

export enum DefaultFilterComparator {
    string = 'includesCaseInsensitive',
    number = 'eq',
    boolean = 'eq',
    date = 'lt',
};

export const DefaultFilterValues = {
    string: '',
    number: undefined,
    float: undefined,
    integer: undefined,
    boolean: true,
    date: new Date().toISOString(),
}

export type FilterField = {
    name: string,
    label: string,
    type: 'string' | 'float' | 'integer' | 'boolean' | 'date' | FilterType,
    comparators?: string[],
    list?: boolean,
    aggregators?: string[];
}

export type FilterSettings = {
    id: string,
    name: string,
    comparator: string,
    aggregator?: string,
    value?: any,
};

export const DefaultListFilter = ListFilters.some;

type FilterProps = {
    moreFilters?: FilterField[],
    currentUser: User;
    queryDisabled: boolean;
    onQuery: () =>  void;
    onFilterChange: (fields: Record<string, object>) => void;
};

export class Filter extends Component<FilterProps, {
    fields: FilterSettings[],
    filters: FilterField[],
    toBeAdded: string | null,
}> {
    constructor(props) {
        super(props);

        this.state = {
            /*
                Change active filters when page loads (bad name, I know)
            */
            fields: [
                {
                    id: uuid(),
                    name: 'barcodes',
                    comparator: 'endsWith',
                    aggregator: 'some',
                    value: '',
                }
            ],
            /*
                Change available filters here.
            */
            filters: [
                {
                    name: 'barcodes',
                    label: 'Tracking Number',
                    type: 'string',
                    list: true,
                    aggregators: ['some'],
                    comparators: ['endsWith'],
                },
                { name: 'dateReceived', label: 'Date Received', type: 'date' },
                // { name: 'undeliverable', label: 'Undeliverable', type: 'boolean', comparators: ['eq'] },
                // { name: 'recipient.fName', label: 'Recipient First Name', type: 'string', comparators: ['includesCaseInsensitive'] },
                { name: 'recipient.lName', label: 'Recipient Last Name', type: 'string', comparators: ['includesCaseInsensitive'] },
            ],
            toBeAdded: null,
        };

        this.onAddSelect = this.onAddSelect.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onFieldSettingChange = this.onFieldSettingChange.bind(this);
        this.onFieldValueChange = this.onFieldValueChange.bind(this);
        this.getFieldComponent = this.getFieldComponent.bind(this);
        this.onDefault = this.onDefault.bind(this);
    }

    onAddSelect = (value) => {
        this.setState({
            toBeAdded: value.name,
        });
    };
    onAdd = () => {
        if (this.state.toBeAdded) {
            const field = this.state.filters.find(f => f.name === this.state.toBeAdded) as FilterField;
            const filter_settings: FilterSettings = {
                id: uuid(),
                name: this.state.toBeAdded,
                comparator: field.comparators && field.comparators.length === 1 ? field.comparators[0] : DefaultFilterComparator[field.type],
                value: DefaultFilterValues[field.type],
            };
            if (field.list) {
                filter_settings.aggregator = field.aggregators && field.aggregators.length === 1 ? field.aggregators[0] : DefaultListFilter;
            }
            this.setState({
                fields: this.state.fields.concat(filter_settings),
            });
        }
    };

    onRemove = (selected: FilterSettings) => () => {
        this.setState({ fields: this.state.fields.filter(f => f.id !== selected.id) });
    }

    onFieldSettingChange = (selected: FilterSettings, thing: 'comparator' | 'aggregator') => (value) => {
        this.setState({
            fields: this.state.fields.map(f => {
                if (f.id === selected.id) {
                    return {
                        ...f,
                        [thing]: value,
                    }
                }
                return f;
            }),
        });
    };

    parseValue = (field: FilterField, selected: FilterSettings, value: string) => {
        switch (field.type) {
            case 'boolean':
                return value === 'true';
            case 'string':
                return ArrayFilters[selected.comparator] ? value.split(',') : value;
            case 'date':
                return new Date(value).toISOString();
            case 'integer':
                return ArrayFilters[selected.comparator] ? value.split(',').map(v => parseInt(v, 10)) : parseInt(value, 10);
            case 'float':
                return ArrayFilters[selected.comparator] ? value.split(',').map(v => parseFloat(v)) : parseFloat(value);
        }
    };

    onDefault = () => {
        this.setState({
            fields: [
                {
                    id: uuid(),
                    name: 'barcodes',
                    comparator: 'endsWith',
                    aggregator: 'some',
                    value: '',
                }
            ]
        });
    };

    onFieldValueChange = (selected: FilterSettings, field: FilterField) => (value) => {
        const selectedValue: string = field.type === 'date' || field.type === 'boolean' ? value : value.target.value;

        this.setState({
            fields: this.state.fields.map(f => {
                if (f.id === selected.id) {
                    return {
                        ...f,
                        value: this.parseValue(field, selected, selectedValue),
                    }
                }
                return f;
            }),
        });
    };

    componentDidMount() {
        // this.onDefault();
        this.props.onFilterChange({});
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.fields, this.state.fields)) {
            let settings = {};

            for (const field of this.state.fields) {
                const filter = this.state.filters.find(f => f.name === field.name) as FilterField;

                const keys = [...field.name.split('.'), filter.list && field.aggregator, field.comparator].filter(Boolean);
                const obj = xzibit(keys, field.value || null);
                if (field.value) {
                    settings = deep_merge(settings, obj);
                }
            }

            this.props.onFilterChange(settings);
        }
    }

    getFieldComponent = (selected: FilterSettings) => {
        const field = this.state.filters.find(f => f.name === selected.name) as FilterField;
        const components: any[] = [];

        const fieldAggs = field.aggregators || Object.values(ListFilters);
        if (field.list && fieldAggs.length > 1) {
            components.push(<DropdownList
                key={uuid()}
                defaultValue={DefaultListFilter}
                data={field.aggregators || Object.values(ListFilters)}
                onChange={this.onFieldSettingChange(selected, 'aggregator')}
            />);
        }

        switch (field.type) {
            case FilterType.boolean:
                const boolComps = field.comparators || Object.keys(BooleanFilters);
                if (boolComps.length > 1) {
                    components.push(<DropdownList
                        key={1}
                        defaultValue={selected.comparator || DefaultFilterComparator.boolean}
                        data={field.comparators || Object.keys(BooleanFilters)}
                        valueComponent={v => BooleanFilters[v.item] || null as any}
                        itemComponent={v => BooleanFilters[v.item] || null as any}
                        onChange={this.onFieldSettingChange(selected, 'comparator')}
                    />);
                }
                components.push(<DropdownList
                    key={2}
                    defaultValue='true'
                    data={['true', 'false']}
                    value={selected.value}
                    onChange={this.onFieldValueChange(selected, field)}
                />);
                break;
            case FilterType.string:
                const strComps = field.comparators || Object.keys(StringFilters);
                if (strComps.length > 1) {
                    components.push(<DropdownList
                        key={1}
                        defaultValue={selected.comparator || DefaultFilterComparator.string}
                        data={field.comparators || Object.keys(StringFilters)}
                        valueComponent={v => StringFilters[v.item] || null as any}
                        itemComponent={v => StringFilters[v.item] || null as any}
                        onChange={this.onFieldSettingChange(selected, 'comparator')}
                    />);
                }
                components.push(<Input
                    key={2}
                    value={selected.value}
                    onChange={this.onFieldValueChange(selected, field)}
                />)
                break;
            case FilterType.float:
            case FilterType.integer:
                const intComparators = field.comparators || Object.keys(NumberFilters);
                if (intComparators.length > 1) {
                    components.push(<DropdownList
                        key={1}
                        defaultValue={selected.comparator || DefaultFilterComparator.number}
                        data={field.comparators || Object.keys(NumberFilters)}
                        valueComponent={v => NumberFilters[v.item] || null as any}
                        itemComponent={v => NumberFilters[v.item] || null as any}
                        onChange={this.onFieldSettingChange(selected, 'comparator')}
                    />);
                }
                components.push(<Input
                    key={2}
                    value={selected.value}
                    onChange={this.onFieldValueChange(selected, field)}
                />)
                break;
            case FilterType.date:
                const dateComparators = field.comparators || Object.keys(DateFilters);
                if (dateComparators.length > 1) {
                    components.push(<DropdownList
                        key={1}
                        defaultValue={selected.comparator || DefaultFilterComparator.date}
                        data={field.comparators || Object.keys(DateFilters)}
                        valueComponent={v => DateFilters[v.item] || null as any}
                        itemComponent={v => DateFilters[v.item] || null as any}
                        onChange={this.onFieldSettingChange(selected, 'comparator')}
                    />);
                }
                components.push(<DateTimePicker
                    key={2}
                    format={'MMM D, YYYY'}
                    value={new Date(selected.value)}
                    onChange={this.onFieldValueChange(selected, field)}
                    onSelect={this.onFieldValueChange(selected, field)}
                />)
                break;
            default:
                break;
        }

        components.push(<button
            key={3}
            onClick={this.onRemove(selected)}
        >x</button>)

        return <div key={selected.id} className="field-container">
            <span className="field-label">{field.label || field.name}</span>
            <div className="field-settings">{components}</div>
        </div>;
    };



    render() {
        return <div className='filter-container'>
            <div className="btn-bar">
                <span className="filters-title">Filters:</span>
                <Button color="info" disabled={this.props.queryDisabled} className='query-btn' onClick={this.props.onQuery}>Query</Button>
                <Button color="warning" className='default-btn' onClick={this.onDefault}>Default Filter</Button>
            </div>
            <div className='fields-container'>
                {this.state.fields.map(this.getFieldComponent)}
            </div>
            <div className='add-container'>
                <span>Add Filter</span>
                <div className='add-workflow'>
                    <DropdownList
                        data={this.state.filters}
                        itemComponent={row => row.item.label}
                        valueComponent={row => row.item && row.item.label || null}
                        onChange={this.onAddSelect}
                    />
                    <button className='add-btn' disabled={!this.state.toBeAdded} onClick={this.onAdd}>+</button>
                </div>
            </div>
        </div>
    }
}