// tslint:disable
// this is an auto generated file. This will be overwritten

export const onCreateDelivery = `subscription OnCreateDelivery {
  onCreateDelivery {
    id
    barcodes
    undeliverable
    dateReceived
    note
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    modifiedBySub
    department
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
export const onUpdateDelivery = `subscription OnUpdateDelivery {
  onUpdateDelivery {
    id
    barcodes
    undeliverable
    dateReceived
    note
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    modifiedBySub
    department
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
export const onDeleteDelivery = `subscription OnDeleteDelivery {
  onDeleteDelivery {
    id
    barcodes
    undeliverable
    dateReceived
    note
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    modifiedBySub
    department
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
