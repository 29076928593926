import {
  REMOVE_DELIVERY,
  MERGE_DELIVERY,
  SET_DELIVERIES,
  SET_RANGE,
  TOGGLE_DELIVERY_MODAL,
  SET_VIEW_UNDELIVERABLE,
  SET_TRACKING_SEARCH,
} from '../constants';
import { Delivery } from '../../Types';
import {
  decDay,
  setZeroHour,
} from '../../utils';
import { setViewUndeliverable } from '../actions';

export type DeliveryStore = {
  deliveries: Delivery[],
  start: Date;
  end: Date;
  deliveryModalOpen: Boolean,
  delivery?: Delivery | null,
  viewUndeliverable: boolean,
  trackingSearch: string | null,
};
const init: DeliveryStore = {
  deliveries: [],
  start: decDay(setZeroHour(new Date()), 7),
  end: setZeroHour(new Date()),
  deliveryModalOpen: false,
  delivery: null,
  viewUndeliverable: false,
  trackingSearch: null,
};


const merge = (deliveries, p) => {
  const payloads = Array.isArray(p) ? p : [p];

  for (const payload of payloads) {
    const index = deliveries.findIndex(e => e.id === payload.id);
    if (index > -1) {
      deliveries.splice(index, 1, payload);
    } else {
      deliveries.push(payload);
    }
  }

  return [...deliveries];
}

export const delivery = (state = init, action) => {
  const { payload, type } = action;
  switch(type) {
    case SET_TRACKING_SEARCH:
      return {
        ...state,
        trackingSearch: payload,
      }
    case SET_DELIVERIES:
      const set_deliveries = merge([], payload);
      return {
        ...state,
        deliveries: set_deliveries,
      }
    case MERGE_DELIVERY:
      const merge_deliveries = merge(state.deliveries, payload);
      return {
        ...state,
        deliveries: merge_deliveries,
      };
    case REMOVE_DELIVERY:
      const id = payload.id;
      return {
        ...state,
        deliveries: state.deliveries.reduce<Delivery[]>((list, item) => {
          if (item.id !== id) {
            list.push(item);
          }
          return list;
        }, [])
      }
    case SET_RANGE:
      return {
        ...state,
        ...payload,
      };
    case SET_VIEW_UNDELIVERABLE:
      return {
        ...state,
        viewUndeliverable: payload,
      }
    case TOGGLE_DELIVERY_MODAL:
      return {
        ...state,
        deliveryModalOpen: !state.deliveryModalOpen,
        delivery: payload,
      }
    default:
      return state;
  }
}
