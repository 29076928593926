import { fork, take, call } from 'redux-saga/effects';

export function* takeFirst(pattern: string, saga) {
    const task = yield fork(function * () {
      while (true) {
        const action = yield take(pattern);
        yield call(saga, action);
      }
    });
    return task;
}