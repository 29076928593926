import * as isEqual from 'lodash.isequal';

export const nullToNull = (obj: any) => {
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    if (value === 'null') {
      obj[key] = null;
    } else if (typeof value === 'object' && value !== null) {
      obj[key] = nullToNull(value);
    }
  }
  return obj;
};

export const extractKeys = (obj: any) => {
  const keys: string[] = [];
  for (const key of Object.keys(obj)) {
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      keys.push(
        ...extractKeys(obj[key]).map(subKey => `${key}.${subKey}`),
      );
    } else {
      keys.push(key);
    }
  }
  return keys;
};

export const xzibit = (keys, value) => {
  return keys.length ? { [keys[0]]: xzibit(keys.slice(1), value) } : value;
};

export const prevIfEqual = (prev, next) => isEqual(prev, next) ? prev : next;

export const deep_get = (keys, obj: object) => {
  let value = obj;
  for (const key of keys) {
    value = value[key];
  }
  return value;
}

/**
 * Merges left to right.
 * If n0 and n1 are both objects, they will merge.
 * If n0 is an object and n1 is a primitive, return n1 and vise versa.
 * If n0[key] is defined (not undefined) and n1[key] is undefined, do nothing
 */
export const deep_merge = (...objs) => {
  let next: any = null;
  for (const obj of objs) {
      if (typeof obj === 'object' && obj != null) {
          if (typeof next !== 'object' || next == null) {
              next = {};
          }
          for (const key in obj) {
              if (
                  typeof next[key] === 'object' && next[key] != null
                  && typeof obj[key] === 'object' && obj[key] != null
              ) {
                  next[key] = deep_merge(next[key], obj[key]);
              } else {
                  next[key] = obj[key] !== undefined  ? obj[key] : next[key];
              }
          }
      } else {
          next = obj;
      }
  }
  return next;
};
