import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './SignedInHeader.css';

export type SignedInHeaderProps = {
  override: any;
  authState: string;
  isAdmin: boolean;
  nav: {
    users: () => void,
    archive: () => void,
    delivery: () => void,
    signOut: () => void,
  }
}

export const SignedInHeader = (props: SignedInHeaderProps) => {
  const { authState, nav, isAdmin } = props;
  if (authState !== 'signedIn') {
    return null;
  }

  const buttons = [
    { nav: nav.delivery, text: 'Delivery', className: 'delivery-button' },
    { nav: nav.users, admin: true, text: 'Users', className: 'users-button' },
    { nav: nav.archive, admin: true, text: 'Archive', className: 'archive-button' },
    { nav: nav.signOut, text: 'Sign Out', color: 'warning', className: 'sign-out-button' },
  ];

  const button_components = buttons.filter(but => isAdmin || !but.admin).map((but, key) => (<Button
    key={key}
    onClick={but.nav}
    className={but.className}
    color={but.color || 'info'}
  >
    {but.text}
  </Button>));

  return <div className="SignedInHeader">{button_components}</div>
}
