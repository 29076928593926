import { CurrentAuth } from '../Types';

export const getCognitoPoolIdFromToken = (idToken: { iss: 'string' }) => idToken.iss.split('.amazonaws.com/')[1];

export const localStorage_getCurrentAuth = (): CurrentAuth => {
  const get = localStorage.getItem('currentAuth');
  return get && JSON.parse(get) || null;
};
export const localStorage_setCurrentAuth = (
  currentAuth: CurrentAuth,
) => {
  const str = JSON.stringify(currentAuth);
  localStorage.setItem('currentAuth', str);
}
export const localStorage_removeCurrentAuth = () => {
  localStorage.removeItem('currentAuth');
}
