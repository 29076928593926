import { UserType, AttributeListType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import { User } from '../Types';

export const getAttribute = (
  user: UserType,
  attribute: 'family_name' | 'given_name' | 'email' | 'phone_number' | 'cognito:username' | 'cognito:groups'
  ) => {
  const attr = user.Attributes && user.Attributes.find(a => a.Name === attribute);
  return attr && attr.Value;
}

export const getAttributeMap = (
  user: UserType,
): { [name: string]: any } => {
  const attr = user.Attributes || [];
  return attr.reduce((map, item) => {
    map[item.Name] = item.Value;
    return map;
  }, {} as any)
}


export const createMutableUserAttrs = (user: User): AttributeListType => {
  const attrs: AttributeListType = [];
  if (user.email) {
    attrs.push({ Name: 'email', Value: user.email });
  }
  if (user.firstName) {
    attrs.push({ Name: 'given_name', Value: user.firstName });
  }
  if (user.lastName) {
    attrs.push({ Name: 'family_name', Value: user.lastName });
  }
  if (user.phoneNumber) {
    attrs.push({ Name: 'phone_number', Value: user.phoneNumber });
  }
  return attrs;
}

export const haveUserAttrsChanged = (user: User, previousUser: User): boolean => (
  user.email !== previousUser.email ||
  user.firstName !== previousUser.firstName ||
  user.lastName !== previousUser.lastName ||
  user.phoneNumber !== previousUser.phoneNumber
);
