import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import { Field } from 'redux-form';
import {
  FieldFormControl,
  FieldFormControlTypePropType,
} from '../components';
import uuid from 'uuid/v4';

export const createField = (
  name: string,
  label: string,
  type: FieldFormControlTypePropType,
  props: any,
) => (
  <Field
    key={name}
    name={name}
    type={type}
    label={label}
    normalize={props.normalize}
    disabled={props.submitting}
    component={FieldFormControl}
    {...props} />
);

const text = (props) => {
  const {
    input,
    accessor,
    label,
    style,
    className,
  } = props;
  return <div className={className} style={style}>
    {label}: <div>{accessor ? accessor(input.value) : input.value}</div>
  </div>
}

export const createText = (
  name: string,
  label: string,
  props: any,
) => <Field
  key={name}
  name={name}
  label={label}
  disabled={props.disabled}
  component={text}
  {...props}
/>

const textField = (props) => {
  const {
    input,
    label,
    style,
    fieldStyle,
    className,
    meta: {
      touched,
      error,
      warning,
    },
  } = props;
  const id = uuid();
  return <div className={className} style={style}>
    {label}<Input
      {...input}
      style={{ ...fieldStyle, fontSize: '.85rem' }}
      id={id}
      type={'text'}
      state={(touched && error) ? 'error' : null}
      disabled={props.disabled} />
  </div>
}

export const createTextDisplay = (
  name: string,
  label: string,
  props: any,
) => <Field
  key={name}
  name={name}
  style={{ fontSize: '.85rem' }}
  label={label}
  disabled={props.disabled}
  component={textField}
  {...props}
/>

export const createCol = elements => (elements.map((ele, i) => <Row key={ele.props.name + i.toString()}><Col>{ele}</Col></Row>))

export const createRow = children => (<Row>{children.map((child, i) => <Col key={`${child.props.name}${i}`}>{child}</Col>)}</Row>);

export const createLabelWidthProps = width => ({
  labelWidth: width,
  labelClass: width === 12 ? 'text-left' : 'text-right',
  controlClass: 'form-control',
  controlWidth: 12 - width,
});
