import React from 'react';
import ReactTable, { Column } from 'react-table';
import { Button } from 'reactstrap';
import { User } from '../../Types';
import "react-table/react-table.css";

export type DeliveriesTableProps = {
  users: User[];
  currentUser: User;
  handleInitForm: (u: User) => void;
  deleteUser: (u: User) => void;
}

const dataColumns: Column[] = [
  {
    Header: 'User',
    columns: [
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
    ]
  },
];

const getDataColumns = (isDev) => {
  if (!isDev) {
    return dataColumns;
  }

  return dataColumns.map(data => ({
    ...data,
    columns: (data.columns || []).concat({ Header: 'Dep', accessor: 'department' })
  }))
}

const UsersTable = (props: DeliveriesTableProps) => {
  const {
    users,
    handleInitForm,
    deleteUser,
    currentUser,
  } = props;

  if (!currentUser) {
    return null;
  }

  const defaultPageSize = 10;
  const actionColumns: Column = {
    Header: 'Actions',
    columns: [
      {
        Header: 'Delete',
        id: 'remove',
        style: { textAlign: 'center' },
        Cell: ({ original }: { original: Required<User> }) => {
          const ownUser = original && currentUser && original.sub === currentUser.sub;
          const cannotDeleteMaintenance = original.isDev &&
            (!currentUser || !(currentUser as Required<User>).isDev);
          return (<Button
              style={{ margin: 'auto' }}
              color={ownUser || cannotDeleteMaintenance ? 'secondary' : 'warning'}
              disabled={ownUser || cannotDeleteMaintenance}
              onClick={() => deleteUser(original)}
            >Delete</Button>)
        }
      },
      {
        Header: 'Edit',
        id: 'edit',
        style: { textAlign: 'center' },
        Cell: ({ original }: { original: User }) => (
          <Button
            color="info"
            onClick={() => handleInitForm(original)}
          >Edit</Button>)
      }
    ]
  };

  return <ReactTable
    data={users}
    columns={getDataColumns(currentUser.isDev).concat(actionColumns)}
    defaultPageSize={defaultPageSize}
  />
}

export default UsersTable;
