import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';

import { auth } from './auth';
import { user } from './user';
import { delivery } from './delivery';
import { recipient } from './recipient';
import { print } from './print';


export default history => combineReducers<any>({
  auth,
  form,
  user,
  print,
  delivery,
  recipient,
  toastr,
  router: connectRouter(history),
});