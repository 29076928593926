import {
  SET_PRINT_FLAG,
} from '../constants';

const init = {
  printFlag: false,
}
export const print = (state = init, action) => {
  const { type, payload } = action;
  switch(type) {
    case SET_PRINT_FLAG:
      return {
        ...state,
        printFlag: payload,
      }
    default:
      return state;
  }
}
