import React, { Component } from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router';
import ReduxToastr from 'react-redux-toastr';

import { history, configureStore } from '../../redux';
import { Auth } from '..';

import DeliveryContainer from '../Delivery';
import UserContainer from '../User';
import ArchiveContainer from '../Archive';

import './App.css';

const store = configureStore({}, history);

class App extends Component {
  render() {
    return (<div className='app'>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Auth>
            <ReduxToastr
              newestOnTop={false}
              preventDuplicates
              progressBar
              position="bottom-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              closeOnToastrClick
            />
            <Switch>
              <Route key='delivery' exact path="/" component={DeliveryContainer} />
              <Route key='user' exact path='/Users' component={UserContainer} />
              <Route key='archive' exact path = '/Archive' component={ArchiveContainer} />
            </Switch>
          </Auth>
        </ConnectedRouter>
      </Provider>
    </div>);
  }
}

export default App;
