import {
  PRINT,
  SET_PRINT_FLAG,
  PUT_DELIVERY_AND_PRINT,
  SET_PRINT_VALUES,
} from '../constants';
import { Delivery } from '../../Types';

export const setPrintFlag = (print: boolean) => ({
  payload: print,
  type: SET_PRINT_FLAG,
});

export const putDeliveryAndPrint = (delivery: Delivery) => ({
  payload: delivery,
  type: PUT_DELIVERY_AND_PRINT,
});

export const print = (delivery: Delivery) => ({
  payload: delivery,
  type: PRINT,
});

export const setPrintValues = (delivery: Delivery) => ({
  payload: delivery,
  type: SET_PRINT_VALUES,
});
