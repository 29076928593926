import React from 'react';
import FileSaver from 'file-saver';
import { Parser }  from 'json2csv';
import { connect } from 'react-redux';
import { Delivery } from '../../Types';
import { Button } from 'reactstrap';
import { extractKeys, dedup, setZeroHour, setEndHour } from '../../utils';
import { listDeliveries, listArchiveDeliveries } from '../../redux/sagas/delivery';
import { QueryForm, QueryFormValues } from './QueryForm';
import DeliveryTable from '../Delivery/DeliveryTable';

import './Archive.css';

type ArchiveProps = {

};

type ArchiveState = {
    data: null | Delivery[];
};

export class Archive extends React.Component<ArchiveProps, ArchiveState> {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        }
    }

    render () {
        const { data } = this.state;
        const onSubmit = async (values: QueryFormValues) => {
            const start = setZeroHour(new Date(values.start));
            const end = setEndHour(new Date(values.end));

            start.setTime(start.getTime() - 1);
            end.setTime(end.getTime());

            const filter: any = {
                dateReceived: {
                    gt: start.toISOString(),
                    lt: end.toISOString(),
                },
            };

            const items: Delivery[] = [];
            let token = undefined;
            let errors = null;

            while (token !== null && errors === null) {
                const res: any = await listDeliveries({
                    filter,
                    limit: 1000,
                    nextToken: token,
                });
                if (res.errors) {
                    errors = res.errors;
                } else {
                    items.push(...res.data.listDelivery.items);
                    token = res.data.listDelivery.nextToken;
                }
            }

            const archive: any = await listArchiveDeliveries({
                beforeDate: end.toISOString(),
                afterDate: start.toISOString(),
            });

            console.log('archive', archive);
            items.push(...archive.data.listArchiveDelivery);

            this.setState({ data: dedup(items, (a, b) => a.id === b.id) });
        };

        const onDownload = () => {
            if (!data || !data.length) {
                return;
            }

            const fields = extractKeys(data[0]);
            const parser = new Parser({ fields });
            const csv: string = parser.parse(data);
            const blob = new Blob([csv], { type: 'text/csv' })
            FileSaver.saveAs(blob, new Date().toISOString() + '.csv');
        }

        const downloadDisabled = !data || !data.length;
        const downloadColor = !data
            ? 'secondary'
            : data && !data.length
            ? 'warning'
            : 'info';

        const prompt = data === null
            ? 'No query has been made.'
            : data.length === 0
                ? 'No data was returned from query.'
                : `${data.length} Deliveries ready to be download.`;

        return (<div className='archive-container'>
            <div className='instructions'>
                Query a date range to be downloaded as a CSV.
            </div>
            <div className='row-container'>
                <div className='query-container'>
                    <QueryForm
                        onSubmit={onSubmit}
                    />
                        <DeliveryTable
                        deliveries={data || []}
                    />
                </div>
                <div className="prompt-container">
                    <div className='prompt'>
                        {prompt}
                    </div>
                    <Button
                        color={downloadColor}
                        disabled={downloadDisabled}
                        onClick={onDownload}
                    >
                        Download
                    </Button>
                </div>

            </div>


        </div>);
    }
}

const mapStateToProps = (state) => {
    return {
    }
  }

  const mapDispatchToProps = dispatch => ({
    dispatch,
  })

  export default connect(mapStateToProps, mapDispatchToProps)(Archive);
