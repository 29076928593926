import { put, select, all, takeEvery } from 'redux-saga/effects';
import { PRINT, PUT_DELIVERY, PUT_DELIVERY_AND_PRINT, SET_PRINT_VALUES } from '../constants';
import {
  putDelivery,
  setPrintFlag,
} from '../actions';

const reduxInitField = "@@redux-form/INITIALIZE";

function* print(action) {
  yield put({
    payload: action.payload,
    type: reduxInitField,
    meta: {
      form: 'print',
    },
  });
  yield put(setPrintFlag(true));
}

function* setPrintValues(action) {
  yield put({
    payload: action.payload,
    type: reduxInitField,
    meta: {
      form: 'print',
    }
  });
  yield put({
    payload: action.payload,
    type: reduxInitField,
    meta: {
      form: 'delivery',
    }
  })
  yield put({
    payload: action.payload.recipient,
    type: reduxInitField,
    meta: {
      form: 'recipient',
    }
  })
}

export default function* () {
  yield all([
    function* () { yield takeEvery(PRINT, print) }(),
    function* () { yield takeEvery(SET_PRINT_VALUES, setPrintValues) }(),
  ])
}
