// tslint:disable
// this is an auto generated file. This will be overwritten

export const getDelivery = `query GetDelivery($id: ID!) {
  getDelivery(id: $id) {
    id
    barcodes
    undeliverable
    dateReceived
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    modifiedBySub
    note
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
export const listDelivery = `query ListDelivery(
  $filter: TableDeliveryFilterInput
  $limit: Int
  $nextToken: String
) {
  listDelivery(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      barcodes
      undeliverable
      dateReceived
      createdBy
      modifiedBy
      dateCreated
      dateModified
      createdBySub
      modifiedBySub
      note
      recipient {
        id
        empId
        lName
        fName
        init
        bldg
        room
        workPhone
        email
        dept
      }
    }
    nextToken
  }
}
`;
export const listArchiveDelivery = `query ListArchiveDelivery(
  $beforeDate: AWSDateTime
  $afterDate: AWSDateTime
) {
  listArchiveDelivery(beforeDate: $beforeDate, afterDate: $afterDate) {
    id
    barcodes
    undeliverable
    dateReceived
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    modifiedBySub
    note
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
