import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import ReactToPrint from 'react-to-print';
import {
  reduxForm,
  Field,
} from 'redux-form';


const flexItemRowStyle = {
  flex: '1, 0, 100%',
  width: '100%',
  borderWidth: 1,
}

const createText = (label: string, value, style = {}) => {
  return <div style={{
    display: 'flex',
    borderColor: 'gray',
    borderStyle: 'solid',
    borderWidth: '0px 1px 1px 0px',
    padding: '.33vw',
    flexWrap: 'wrap',
    ...style
  }}>
    <span style={{
      ...flexItemRowStyle,
      fontSize: 10,
    }}>{label}</span>
    <span style={{
      ...flexItemRowStyle,
      fontSize: 13,
    }}>{Array.isArray(value) ? value.join(', ') : value}</span>
  </div>
}

const createLine = (label: string, length: string, style?) => {
  return <span style={{
    fontSize: 12,
    marginTop: '1vh',
    width: length,
    borderWidth: `0px 0px 1px 0px`,
    borderColor: 'black',
    borderStyle: 'solid',
    ...style,
  }}>
    {label}
  </span>
}

const flexItemColStyle = {
  flex: 1,
}

const recipientComponent = (props) => {
  const {
    lName, fName,
    bldg, dept, room,
    workPhone, email
  } = props.input.value;

  return <div style={{
    display: 'flex',
    flexWrap: 'wrap',
    borderWidth: '1px 0px 0px 1px',
    borderColor: 'gray',
    borderStyle: 'solid',
    ...props.style,
  }}>
    <div style={{
      ...flexItemRowStyle,
      display: 'flex',
    }}>
      {createText('Recipient', `${lName}, ${fName}`, { flex: 7 })}
    </div>
    <div style={{
      ...flexItemRowStyle,
      display: 'flex',
    }}>
      {createText('Building', bldg, { flex: 3 })}
      {createText('Department', dept, { flex: 7 })}
    </div>
    <div style={{
      ...flexItemRowStyle,
      display: 'flex',
    }}>
      {createText('Room', room, flexItemColStyle)}
      {createText('Work Phone', workPhone, flexItemColStyle)}
      {createText('Email', email, flexItemColStyle)}
    </div>
  </div>;
}

const recField = (props) => <Field
  {...props}
  name={'recipient'}
  key={'recipient'}
  disabled={true}
  component={recipientComponent}
/>;

const dateReceivedField = (props: any) => <Field
  {...props}
  name={'dateReceived'}
  key={'dateReceived'}
  disabled={true}
  component={(props: any) => createText('Date Received', (new Date(props.input.value)).toLocaleDateString(), props.style)}
/>

const barcodesField = (props: any) => <Field
  {...props}
  name={'barcodes'}
  key={'barcodes'}
  disabled={true}
  component={(props: any) => createText('Tracking Number', props.input.value, props.style)}
/>;

const noteField = (props: any) => <Field
  {...props}
  name={'note'}
  key={'note'}
  disabled={true}
  component={(props: any) => createText('Note', props.input.value, props.style)}
/>;

export const initialDeliveryPrintValues = {
  id: null,
  barcodes: null,
  recipient: null,
  sub: null,
  username: null,
  dateReceived: null,
};

export type DeliveryPrintProps = {
  setPrintFlag: (shouldPrint: boolean) => void;
  printFlag: boolean;
  onAfterPrint: () => void;
  currentFormId: string;
  deliveryDirty: boolean,
  recipientDirty: boolean,
};

class DeliveryPrint extends Component<DeliveryPrintProps> {
  printContent: any;
  printButtonRef: any;
  reactToPrint: any;

  constructor(props) {
    super(props);
  }
  shouldComponentUpdate(nextProps) {
    return nextProps.printFlag ||
    nextProps.currentFormId !== this.props.currentFormId ||
    nextProps.deliveryDirty !== this.props.deliveryDirty ||
    nextProps.recipientDirty !== this.props.recipientDirty;
  }
  componentDidUpdate() {
    const {
      printFlag,
      setPrintFlag,
    } = this.props;
    if (printFlag) {
      setPrintFlag(false);
      (this.reactToPrint as any).handlePrint();
    }
  }

  render() {
    const { currentFormId, deliveryDirty, recipientDirty } = this.props;

    return <div className='ml-auto'>
      <ReactToPrint
        ref={ref => this.reactToPrint = ref}
        onAfterPrint={this.props.onAfterPrint}
        trigger={() => <Button
          color="info"
          style={{ marginRight: '.25vw' }}
          disabled={!currentFormId || deliveryDirty || recipientDirty}
          ref={ref => this.printButtonRef = ref}
        >Print</Button> as any}
        content={() => this.printContent}
      />
      {/*
        Use fixed position to hide form off page
      */}
      <div style={{ position: 'fixed', top: '200vw' }}>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            width: '6in',
            height: '4in',
            padding: '1vw',
            // borderWidth: 1,
            // borderStyle: 'solid',
          }}
          ref={ele => this.printContent = ele}
        >
          {barcodesField({
            style: flexItemRowStyle,
            borderWidth: '0px 1px 1px 1px',
            span: {
              alignSelf: 'start',
            }
          })}
          {recField({ style: flexItemRowStyle, flex: '3, 0, 100%'})}
          {noteField({
            style: flexItemRowStyle,
            borderWidth: '0px 1px 0px 1px',
            span: {
              alignSelf: 'start',
            }
          })}
          <div style={{
            ...flexItemRowStyle,
            display: 'flex',
            alignItems: 'flex-end',
          }}>
            {dateReceivedField({
              style: {
                width: '30%',
                marginRight: '1%',
                borderWidth: '0 0 1px 0',
                span: {
                  alignSelf: 'start',
                }
              }
            })}
            {createLine('Date Delivered', '100%', { flex: 1 })}
          </div>
          <div style={{
            ...flexItemRowStyle,
            display: 'flex',
            alignItems: 'flex-end',
          }}>
            {createLine('Signature', '60%', { flex: 4, marginRight: '1%' })}
            {createLine('Initials', '39%', { flex: 1 })}
          </div>
        </div>
      </div>
    </div>
  }
}

export default reduxForm<any, any>({
  initialValues: initialDeliveryPrintValues,
  form: 'print',
})(DeliveryPrint);