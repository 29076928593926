export {
  createTextDisplay,
  createField,
  createCol,
  createLabelWidthProps,
  createRow,
  createText,
} from './react';
export {
  createMutableUserAttrs as createUserAttrs,
  haveUserAttrsChanged,
  getAttribute,
  getAttributeMap,
} from './users';
export { takeFirst } from './saga';
export {
  getCognitoPoolIdFromToken,
  localStorage_getCurrentAuth,
  localStorage_removeCurrentAuth,
  localStorage_setCurrentAuth,
} from './auth';
export {
  isEmail,
  normalizePhone,
} from './form';
export {
  decDay,
  incDay,
  setZeroHour,
  setEndHour,
  iso,
  epoch,
} from './date';
export { dedup } from './array';
export { sanitizeEmptyStrings, sanitizeGraphqlRes } from './aws';
export { isTestEnv } from './misc';
export {
  nullToNull,
  extractKeys,
  deep_merge,
  xzibit,
  deep_get,
  prevIfEqual,
} from './obj';

export const newDebounce = (f, ms) => {
  let lastExec = 0;
  let timeout: any = null;
  return (...args) => {
      if (Date.now() - lastExec < ms && timeout) {
          clearTimeout(timeout);
      }

      timeout = setTimeout(() => f(...args), ms);
      lastExec = Date.now();
  };
};

/**
 * For a given function or async function, call the function.
 * If the function returns false, call the function again after the provided ms. (Default: 500)
 * For every consequative retry, the function is called after twice the amount of time of the previous retry.
 * The function will be called 1 + the given amount of retries. (Default: 5)
 * The function, by default, swallows errors unless otherwise specified with the throwError argument.
 * If retries run out, throws last error received or returns false if the function called did not error.
 * @param {*} f
 * @param {*} ms
 * @param {*} retries
 * @param {*} throwError
 */
export const backoffRetry = (fn, timeout = 300, retries = 5, throwError = false) => {
  return new Promise((res, rej) => {
      let retry = 0;
      let error = null;
      const respawn = (fn, ms) => {
          if (retry <= retries) {
              setTimeout(() => {
                  Promise.resolve(fn()).then(result => {
                      if (result === false) {
                          retry++;
                          respawn(fn, ms ? ms * 2 : timeout);
                      } else {
                          res(result);
                      }
                  }).catch(err => {
                      if (throwError) {
                          rej(err);
                      } else {
                          error = err;
                          respawn(fn, ms ? ms * 2 : timeout);
                      }
                  });
              }, ms);
          } else {
              error ? rej(error) : res(false);
          }
      };
      respawn(fn, 0);
  });
};
