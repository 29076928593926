import {
  Store,
  compose,
  createStore,
  ReducersMapObject,
  applyMiddleware,
  Middleware,
} from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { SagaIterator, Task } from 'redux-saga';

import rootSaga from './sagas';
import rootReducer from './reducers';

export interface IStore<T> extends Store<T> {
  runSaga?: (saga: (...args: any[]) => SagaIterator, ...args: any[]) => Task;
  asyncReducers?: ReducersMapObject;
}

export const history = createBrowserHistory();

export const configureStore = <T>(initState = {}, history) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares: Middleware[] = [
    sagaMiddleware,
    routerMiddleware(history),
  ]

  const __REDUX_DEVTOOLS_EXTENSION__ = (window as any).__REDUX_DEVTOOLS_EXTENSION__;


  const composeArgs = [applyMiddleware(...middlewares)];
  if (__REDUX_DEVTOOLS_EXTENSION__) {
    composeArgs.push(__REDUX_DEVTOOLS_EXTENSION__());
  }
  const store: IStore<T> = createStore<T, any, any, any>(
    rootReducer(history),
    compose(
      ...composeArgs,
      // applyMiddleware(...middlewares),
    ),
  );

  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {};
  store.runSaga(rootSaga as any);

  return store;
}
