/*
  See action files to see what the actions, which correspond to these constants, do
*/

/* auth */
export const SET_AUTH_STATE = 'app/auth/SET_AUTH_STATE';
export const POPULATE_CURRENT_USER = 'app/auth/POPULATE_CURRENT_USER';
export const SET_CURRENT_USER = 'app/auth/SET_CURRENT_USER';
export const SIGN_OUT = 'app/auth/SIGN_OUT';

/* delivery */
export const SUBSCRIBE_TO_DELIVERIES = 'app/delivery/SUBSCRIBE_TO_DELIVERIES';
export const UNSUBSCRIBE_FROM_DELIVERIES = 'app/delivery/UNSUBSCRIBE_FROM_DELIVERIES';
export const POPULATE_DELIVERIES = 'app/delivery/POPULATE_DELIVERIES';
export const SET_DELIVERIES = 'app/delivery/SET_DELIVERIES';
export const MERGE_DELIVERY = 'app/delivery/MERGE_DELIVERY';
export const REMOVE_DELIVERY = 'app/delivery/REMOTE_DELIVERY';
export const PUT_DELIVERY = 'app/delivery/PUT_DELIVERY';
export const DELETE_DELIVERY = 'app/delivery/DELETE_DELIVERY';
export const SET_RANGE = 'app/delivery/SET_RANGE';
export const SET_VIEW_UNDELIVERABLE = 'app/delivery/SET_VIEW_UNDELIVERABLE';
export const TOGGLE_DELIVERY_MODAL = 'app/delivery/TOGGLE_DELIVERY_MODAL';
export const SET_TRACKING_SEARCH = 'app/delivery/SET_TRACKING_SEARCH';

/* recipient */
export const POPULATE_RECIPIENTS = 'app/recipient/POPULATE_RECIPIENTS';
export const SET_RECIPIENTS = 'app/recipient/SET_RECIPIENTS';
export const SET_RECIPIENT_SEARCH_TERM = 'app/recipient/SET_RECIPIENT_SEARCH_TERM';

/* user */
export const POPULATE_USERS = 'app/user/POPULATE_USERS';
export const SET_USERS = 'app/user/SET_USERS';
export const PUT_USER = 'app/user/PUT_USERS';
export const MERGE_USER = 'app/user/MERGE_USER';
export const REMOVE_USER = 'app/user/REMOVE_USER';
export const DELETE_USER = 'app/user/DELETE_USER';
export const SUBSCRIBE_TO_USERS = 'app/user/subscribeToUsers';
export const UNSUBSCRIBE_FROM_USERS = 'app/user/unsubscribeFromUsers';
export const RESET_USER_PASSWORD = 'app/user/resetUserPassword';

/* print */
export const PUT_DELIVERY_AND_PRINT = 'app/print/PUT_DELIVERY_AND_PRINT';
export const SET_PRINT_FLAG = 'app/print/SET_PRINT_FLAG';
export const PRINT = 'app/print/PRINT';
export const SET_PRINT_VALUES = 'app/print/SET_PRINT_VALUES';
