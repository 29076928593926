// NOTE: Generated in the sunyupst-mailroom-appsync repo

export const objSchemaMap = {
  "Recipient": {
    "name": "Recipient",
    "props": [
      {
        "name": "id",
        "required": true,
        "type": "ID",
        "primaryKey": true
      },
      {
        "name": "empId",
        "required": true,
        "type": "String",
        "displayName": "Employee ID"
      },
      {
        "name": "lName",
        "type": "String",
        "displayName": "Last Name"
      },
      {
        "name": "fName",
        "type": "String",
        "displayName": "First Name"
      },
      {
        "name": "init",
        "type": "String",
        "displayName": "Inital"
      },
      {
        "name": "bldg",
        "type": "String",
        "displayName": "Building"
      },
      {
        "name": "room",
        "type": "String",
        "displayName": "Room"
      },
      {
        "name": "workPhone",
        "type": "String",
        "displayName": "Work Phone"
      },
      {
        "name": "email",
        "type": "String",
        "displayName": "Email"
      },
      {
        "name": "dept",
        "type": "String",
        "displayName": "Department"
      }
    ]
  },
  "Delivery": {
    "name": "Delivery",
    "props": [
      {
        "name": "id",
        "required": true,
        "type": "ID",
        "primaryKey": true
      },
      {
        "name": "barcodes",
        "required": true,
        "list": true,
        "type": "String",
        "displayName": "Tracking"
      },
      {
        "name": "undeliverable",
        "type": "Boolean",
        "displayName": "Undeliverable"
      },
      {
        "name": "dateReceived",
        "required": true,
        "type": "AWSDateTime",
        "index": true
      },
      {
        "name": "note",
        "type": "String"
      },
      {
        "name": "createdBy",
        "required": true,
        "type": "String"
      },
      {
        "name": "modifiedBy",
        "required": true,
        "type": "String"
      },
      {
        "name": "dateCreated",
        "required": true,
        "type": "AWSDateTime",
        "displayName": "Date Received"
      },
      {
        "name": "dateModified",
        "required": true,
        "type": "AWSDateTime"
      },
      {
        "name": "createdBySub",
        "required": true,
        "type": "String"
      },
      {
        "name": "modifiedBySub",
        "required": true,
        "type": "String"
      },
      {
        "name": "recipient",
        "type": "Recipient"
      }
    ],
    "root": true
  }
}
