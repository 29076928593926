import React, { Component, ReactNode } from 'react';
import { isTestEnv } from '../../utils';
import {
  Authenticator,
  SignUp,
  ConfirmSignUp,
  Greetings,
} from 'aws-amplify-react';
import { version } from '../../../package.json';
import { push as routerPush } from 'connected-react-router';
import { connect } from 'react-redux';
// redux
import {
  setAuthState,
  signOut,
  populateCurrentUser,
} from '../../redux/actions';
// components
import { SignedInHeader } from './SignedInHeader';
// Types
import { User } from '../../Types';

export type AuthProps = {
  setAuthState: (state: string) => void;
  signOut: () => void;
  routerPush: (path: string, state: any) => void;
  populateCurrentUser: () =>  void;

  authState: string;
  currentUser: User,
}

class Auth extends Component<AuthProps, any, any> {
  _validAuthStates: string[];
  showComponent: any;
  constructor(props) {
    super(props);
    this._validAuthStates = ['signedIn'];
    this.navigate = this.navigate.bind(this);
    this.navUsers = this.navUsers.bind(this);
    this.navDel = this.navDel.bind(this);
    this.navArch = this.navArch.bind(this);
    this.navSignOut = this.navSignOut.bind(this);

    this.handleStateChange = this.handleStateChange.bind(this);
  }

  navigate = (location: string) => {
    const { currentUser, authState, routerPush } = this.props;
    const saveState = {
      auth: {
        currentUser,
        authState,
      }
    };
    routerPush(
      location,
      saveState,
    );
  }


  navUsers = () => {
    this.navigate('/Users');
  }

  navDel = () => {
    this.navigate('/');
  }

  navArch = () => {
    this.navigate('/Archive');
  }

  navSignOut = () => {
    const { routerPush } = this.props;
    this.props.signOut();
    routerPush('/', {});
  }


  handleStateChange = (state) => {
    if (state === 'signedIn') {
      this.props.populateCurrentUser();
    } else if (state === 'signedOut') {
      this.props.signOut();
    }
    console.log('auth state change', state);
    this.props.setAuthState(state);
  }

  authProps = {
    onStateChange: this.handleStateChange,
    hide: [
      SignUp,
      ConfirmSignUp,
      Greetings,
    ],
  }

  render () {
    const {
      children,
      authState,
      currentUser,
    } = this.props;

    /*
      Note: this has to be assembled in this format.
        The Authenticator class doesn't check for null/undefined children while iterating through them.
        Maybe I'm declaring custom children incorrectly ? doesn't matter
        Wrapping the children in a div causes a series of react-warnings to be spit out into the console.
        Using an empty React Component to wrap a potential null is dumb/inefficent.
    */
    let renderChildren: (Element | ReactNode)[] = (isTestEnv() ? [<div
      key={'stageHeader'}
      style={{
        backgroundColor: 'black',
        color: 'yellow',
        paddingLeft: '.30vw',
        fontSize: '20px',
      }}
    >Staging/Test Environment</div>] : []).concat(
      <SignedInHeader
        key={'signedInHeader'}
        override={Greetings}
        authState={authState}
        isAdmin={!!(currentUser && currentUser.isAdmin)}
        nav={{
          users: this.navUsers,
          signOut: this.navSignOut,
          delivery: this.navDel,
          archive: this.navArch,
        }}
      />
    );
    if (authState === 'signedIn') {
      renderChildren = renderChildren.filter(Boolean).concat(children);
    }

    renderChildren.push(<div style={{ fontSize: '0.75rem', position: 'absolute', left: '1rem', top: 0, color: 'gray' }}>
      {version}
    </div>)

    return <Authenticator {...this.authProps} >
      {renderChildren}
    </Authenticator>
  }
}

const mapStateToProps = (state) => {
  return {
    authState: state.auth.authState,
    currentUser: state.auth.currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  setAuthState: (state: string) => dispatch(setAuthState(state)),
  signOut: () => dispatch(signOut()),
  routerPush: (path: string, state: any) => dispatch(routerPush(path, state)),
  populateCurrentUser: () =>  dispatch(populateCurrentUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)
