// tslint:disable
// this is an auto generated file. This will be overwritten

export const createDelivery = `mutation CreateDelivery($input: CreateDeliveryInput!) {
  createDelivery(input: $input) {
    id
    barcodes
    undeliverable
    dateReceived
    note
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    modifiedBySub
    department
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
export const updateDelivery = `mutation UpdateDelivery($input: UpdateDeliveryInput!) {
  updateDelivery(input: $input) {
    id
    barcodes
    undeliverable
    dateReceived
    note
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    modifiedBySub
    department
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
export const deleteDelivery = `mutation DeleteDelivery($input: DeleteDeliveryInput!) {
  deleteDelivery(input: $input) {
    id
    barcodes
    undeliverable
    dateReceived
    note
    createdBy
    modifiedBy
    dateCreated
    dateModified
    createdBySub
    department
    modifiedBySub
    recipient {
      id
      empId
      lName
      fName
      init
      bldg
      room
      workPhone
      email
      dept
    }
  }
}
`;
