import {
  POPULATE_RECIPIENTS,
  SET_RECIPIENTS,
  SET_RECIPIENT_SEARCH_TERM,
} from '../constants';

import { Recipient } from '../../Types';

/**
 * trigger call for recipients to be retrieved remotely and set in the store
 */
export const populateRecipients = () => ({
  payload: null,
  type: POPULATE_RECIPIENTS,
});

/**
 * set recipients in the store
 * @param recipients
 */
export const setRecipients = (recipients: Recipient[]) => ({
  payload: recipients,
  type: SET_RECIPIENTS,
});

export const setRecipientSearchTerm = (searchTerm: string) => ({
  payload: searchTerm,
  type: SET_RECIPIENT_SEARCH_TERM,
})

