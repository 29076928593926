import React, { Component } from 'react';
import { Button, Row, Col, FormText } from 'reactstrap';
import { Field } from 'redux-form';
import DeliveryPrint from './DeliveryPrint';
import {
  reduxForm,
  InjectedFormProps,
  isDirty,
} from 'redux-form';
import { Empty } from '../../components';
import { createField } from '../../utils';
import { v4 as uuid } from 'uuid';

import './CustomMultiSelect.css';

export type DeliveryInputFormProps = {
  onSubmit: (values: any) => Promise<void>;
  onSubmitPrint: (values: any) => Promise<void>;
  onDelete: (d: any) => Promise<void>;
  initializeForm: (initValues: any) => void;
  setDateReceived: (date: Date) => void;
  handleTrackingFieldChange: (trackingNums: string[], previousTrackingNums: string[]) => string[];
  currentFormId: string;
  onAfterPrint: () => void;
  setPrintFlag: (shouldPrint: boolean) => void;
  printFlag: boolean;
  recipientFormDirty: boolean;
  children: any;
};

const validate = (values, props) => {
  const { deliveryBarcodesValid } = props;
  const errors: any = {};
  if (!values.barcodes || !values.barcodes.length) {
    errors.barcodes = 'Must provide at least one barcode.';
  }
  return errors;
}

const multiselect: any = (props) => {
  const valueCompList: any[] = [];

  const {
    onChange,
  } = props.input;

  const valueList = Array.isArray(props.input.value) ? props.input.value : [];

  for (let idx = 0; idx < valueList.length; idx++) {
    const valueItem = valueList[idx];
    valueCompList.push(<div key={valueItem.id} className='value-row'>
      <input
        className='form-control'
        defaultValue={valueItem.value}
        onChange={e => {
          const nextList: any[] = [];
          for (let sIdx = 0; sIdx < valueList.length; sIdx++) {
            if (sIdx === idx) {
              nextList.push({ value: e.currentTarget.value, id: valueItem.id });
            } else {
              nextList.push(valueList[sIdx]);
            }
          }
          onChange(nextList);
        }}
      />
      <Button
        color='warning'
        onClick={() => {
          const nextList: any[] = [];
          for (let sIdx = 0; sIdx < valueList.length; sIdx++) {
            if (sIdx !== idx) {
              nextList.push(valueList[sIdx]);
            }
          }
          onChange(nextList);
        }}
      >Remove</Button>
    </div>);
  }

  const field = <div className='cms'>
    <span className='label'>Tracking Numbers</span>

    {valueCompList.length ?
      <div className='value-container'>
        {valueCompList}
      </div>
    : null}

    <input
      className='form-control input'
      onKeyPress={event => {
        if (event.key === 'Enter') {
          const currentValue = event.currentTarget.value;
          if (currentValue.length > 0) {
            onChange(valueList.concat({ value: event.currentTarget.value, id: uuid() }));
            event.currentTarget.value = '';
          }
        }
      }}
    />
  </div>

  const {
    touched,
    error,
    warning,
  } = props.meta;

  return <div className='ffc'>
    {field}
    {touched && (
        (error &&
          <FormText className='ffc-error'>
            {error}
          </FormText>) ||
        (warning &&
          <FormText className='ffc-warning'>
            {warning}
          </FormText>)
      )}
  </div>
}

export const initialValues: any = {
  id: null,
  barcodes: null,
  recipient: null,
  undeliverable: false,
  createdBySub: null,
  modifiedBySub: null,
  createdBy: null,
  modifiedBy: null,
  dateReceived: null,
  note: null,
};

const DeliveryInputForm = (props: DeliveryInputFormProps) => {
  const {
    submitting,
    onSubmit,
    handleSubmit,
    valid,
    dirty,
    currentFormId,
    initializeForm,
    onDelete,
    handleTrackingFieldChange,
    onAfterPrint,
    setPrintFlag,
    printFlag,
    recipientFormDirty,
    setDateReceived,
  } = props as DeliveryInputFormProps & InjectedFormProps;

    return (
        <div className="delivery-form">
        <div className='fields-container'>
          {
            <Field
              key={'barcodes'}
              name={'barcodes'}
              component={multiselect}
            />
          }
          {
            createField(
              'note',
              'Note',
              'textarea',
              { style: { fontSize: '.85rem' } },
            )
          }
            <div className="delivery-row">
                {createField(
                  'dateReceived',
                  'Date Received',
                  'datetimepicker',
                  {
                    className: 'delivery-field date-received-field',
                    children: [
                      <Button
                        className="now-button"
                        type="submit"
                        style={{  }}
                        color="success"
                        onClick={() => setDateReceived(new Date())}
                      ><i className={`fa ${submitting ? 'fa-circle-o-notch fa-spin' : 'fa-save'}`} />Now</Button>
                    ]
                  },
                )}
                {createField(
                  'undeliverable',
                  'Undeliverable',
                  'checkbox',
                  {
                    className: 'undeliverable-field',
                    style: { marginLeft: '.3rem' },
                    labelAlignment: 'row',
                  },
                )}
            </div>


            <div className="divider" />

            <div className="child-container" >
                {props.children}
            </div>


            <div className="button-bar">
                {
                    currentFormId &&
                    <Button
                    color="danger"
                    disabled={submitting}
                    onClick={handleSubmit(onDelete)}
                    ><i className={`fa del-btn ${submitting ? 'fa-circle-o-notch fa-spin' : 'fa-save'} `} />Delete</Button>
                }

                <DeliveryPrint
                    onAfterPrint={onAfterPrint}
                    setPrintFlag={setPrintFlag}
                    printFlag={printFlag}
                    currentFormId={currentFormId}
                    deliveryDirty={dirty}
                    recipientDirty={recipientFormDirty}
                />

                <Button
                    color="warning"
                    disabled={submitting}
                    onClick={() => initializeForm(initialValues)}
                ><i className={`fa ${submitting ? 'fa-circle-o-notch fa-spin' : 'fa-save'} `} />Clear</Button>

                <Button
                    className=""
                    type="submit"
                    style={{ width: 240 }}
                    color="success"
                    disabled={submitting || !valid || (!dirty && !recipientFormDirty)}
                    onClick={handleSubmit(onSubmit)}
                ><i className={`fa ${submitting ? 'fa-circle-o-notch fa-spin' : 'fa-save'} `} />Save</Button>
            </div>
        </div>
    </div>
  );
};

export default reduxForm<any, DeliveryInputFormProps>({
  initialValues,
  validate,
  form: 'delivery',
})(DeliveryInputForm);

