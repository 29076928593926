import {
  POPULATE_USERS,
  SET_USERS,
  PUT_USER,
  DELETE_USER,
  REMOVE_USER,
  MERGE_USER,
  SUBSCRIBE_TO_USERS,
  UNSUBSCRIBE_FROM_USERS,
  RESET_USER_PASSWORD,
} from '../constants';

import { User } from '../../Types';

/**
 * trigger call for users to be retrieved remotely and set in the store
 */
export const populateUsers = () => ({
  payload: null,
  type: POPULATE_USERS,
});

/**
 * set users in the store
 * @param users
 */
 export const setUsers = (users: User[]) => ({
  payload: users,
  type: SET_USERS,
});

/**
 * trigger call for a user to be created/updated in the remote database, depending on whether or not an id is falsy/truthy
 * @param user
 */
export const putUser = (user: User) => ({
  payload: user,
  type: PUT_USER,
});

/**
 * merge a user into deliveries of the store
 * @param user
 */
export const mergeUser = (user: User) => ({
  payload: user,
  type: MERGE_USER,
});

/**
 * remove a user from deliveries in the store
 * @param user
 */
export const removeUser = (sub: string) => ({
  payload: sub,
  type: REMOVE_USER,
});

/**
 * trigger call for user to be deleted from the remote database
 * @param user
 */
export const deleteUser = (user: User) => ({
  payload: user,
  type: DELETE_USER,
});

export const subscribeToUsers = () => ({
  payload: null,
  type: SUBSCRIBE_TO_USERS,
});

export const unsubscribeFromUsers = () => ({
  payload: null,
  type: UNSUBSCRIBE_FROM_USERS,
});

export const resetUserPassword = (user: User) => ({
  payload: user,
  type: RESET_USER_PASSWORD
});
