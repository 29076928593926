export enum Department {
  mailroom = 'mailroom',
  receiving = 'receiving',
  all = 'all',
}

export enum Role {
  admin = 'admin',
  dev = 'dev',
  user = 'user',
}
